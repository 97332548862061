import { createContext, useContext, useEffect, useRef, useState } from 'react'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

import { isFunction, isNull, isUndefined } from 'libs/utils'

mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN

const MapContext = createContext()

export const ProvideMap = ({ children, ...props }) => {
	const dataWrapper = useProvideMap(props)

	return (
		<MapContext.Provider value={ dataWrapper }>
			{ children }
		</MapContext.Provider>
	)
}

// Hook for child components to get the User object ...
// ... and re-render when it changes.
export const useMap = () => {
	return useContext(MapContext)
}

// Provider hook that creates Data object and handles state
const useProvideMap = props => {

	const containerRef = useRef(null)
	const map = useRef(null)
	const [isMapLoaded, setIsMapLoaded] = useState(false)

	const [mapOptions, _setMapOptions] = useState({
		lng: 1.9646,
		lat: 46.7917,
		zoom: 4,
		scrollZoom: false,
		onMarkerClick: null,
		...(props.mapOptions || {})
	})
	
	const [items, setItems] = useState(null)
	const [isMarkersLoaded, setIsMarkersLoaded] = useState(false)
	const [selectedMarker, setSelectedMarker] = useState(null)

	useEffect(
		() => {
			if (isNull(containerRef.current) || !isNull(map.current))
				return
				
			map.current = new mapboxgl.Map({
				container: containerRef.current,
				style: 'mapbox://styles/mapbox/streets-v11',
				center: [mapOptions.lng, mapOptions.lat],
				zoom: mapOptions.zoom,
				scrollZoom: mapOptions.scrollZoom
			})

			// Add navigation control (the +/- zoom buttons)
			map.current.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'top-left')
				
			map.current.on('move', () => {
				_setMapOptions(mapOptions => ({
					...mapOptions,
					lng: map.current.getCenter().lng.toFixed(4),
					lat: map.current.getCenter().lat.toFixed(4),
					zoom: map.current.getZoom().toFixed(2)
				}))
			})

			map.current.on('load', () => setIsMapLoaded(true))

			// Clean up on unmount
			return () => {
				setItems(null)
				setIsMarkersLoaded(false)
				setSelectedMarker(null)
				map.current.remove()
			}
		},
		[]
	)

	useEffect(
		() => {
			if (!isMapLoaded || isNull(items))
				return

			const data = toGeoJsonFeatureCollection(items)

			map.current.addSource('markers', {
				type: 'geojson',
				data,
				cluster: true,
				clusterMaxZoom: 14, // Max zoom to cluster points on
				clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
			})

			map.current.addLayer({
				id: 'clusters',
				type: 'circle',
				source: 'markers',
				filter: ['has', 'point_count'],
				paint: {
				// Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
				// with three steps to implement three types of circles:
				//   * Blue, 20px circles when point count is less than 100
				//   * Yellow, 30px circles when point count is between 100 and 750
				//   * Pink, 40px circles when point count is greater than or equal to 750
					'circle-color': "#fff",
					'circle-radius': [
						'step',
						['get', 'point_count'],
						18,
						10,
						22,
						20,
						26
					],
					'circle-stroke-width': 2,
					'circle-stroke-color': '#c8102e'
				}
			})  

			map.current.addLayer({
				id: 'cluster-count',
				type: 'symbol',
				source: 'markers',
				filter: ['has', 'point_count'],
				layout: {
					'text-field': '+{point_count_abbreviated}',
					'text-font': ["Montserrat Regular","Arial Unicode MS Regular"]
				},
				paint: {
					'text-color': "#c8102e"
				}
			})

			map.current.addLayer({
				id: 'unclustered-point',
				type: 'circle',
				source: 'markers',
				filter: ['!', ['has', 'point_count']],
				paint: {
					'circle-color': [
						'case',
						['boolean', ['feature-state', 'selected'], false],
						"#c8102e",
						"#fff"
					],
					'circle-radius': 18,
					'circle-stroke-width': 1,
					'circle-stroke-color': [
						'case',
						['boolean', ['feature-state', 'selected'], false],
						"#c8102e",
						"#999"
					]
				}
			})

			map.current.addLayer({
				id: 'unclustered-point-index',
				type: 'symbol',
				source: 'markers',
				filter: ['!', ['has', 'point_count']],
				layout: {
					'text-field': '{position}',
					'text-font': ["Montserrat Bold","Arial Unicode MS Bold"]
				},
				paint: {
					'text-color': [
						'case',
						['boolean', ['feature-state', 'selected'], false],
						"#fff",
						"#000"
					]
				}
			})
			
			map.current.on('click', 'clusters', (e) => {
				const features = map.current.queryRenderedFeatures(e.point, {
					layers: ['clusters']
				})

				const clusterId = features[0].properties.cluster_id
				map.current.getSource('markers').getClusterExpansionZoom(
					clusterId,
					(err, zoom) => {
						if (err) return
				 
						map.current.easeTo({
							center: features[0].geometry.coordinates,
							zoom: zoom
						})
					}
				)
			})
			
			map.current.on('mouseenter', 'clusters', () => {
				map.current.getCanvas().style.cursor = 'pointer'
			})
			
			map.current.on('mouseleave', 'clusters', () => {
				map.current.getCanvas().style.cursor = ''
			})
			
			if (!isNull(selectedMarker)) {
				panToMarkerItem(selectedMarker)
				isFunction(mapOptions.onMarkerClick) && mapOptions.onMarkerClick(selectedMarker)

			} else {
				map.current.fitBounds(
					getGeoJsonBounds(data), 
					{ 
						padding: 40, 
						maxZoom: 14 
					}
				)
			}

			setIsMarkersLoaded(true)
		},
		[items, isMapLoaded]
	)

	useEffect(
		() => {
			if (!isMarkersLoaded || !isFunction(mapOptions.onMarkerClick))
				return 

			const handleClick = e => {
				isFunction(mapOptions.onMarkerClick) && mapOptions.onMarkerClick(e.features[0].id, e)
			}

			const handleMouseenter = () => {
				map.current.getCanvas().style.cursor = 'pointer'
			}

			const handleMouseleave = () => {
				map.current.getCanvas().style.cursor = ''
			}

			map.current.on('click', 'unclustered-point', handleClick)
			map.current.on('mouseenter', 'unclustered-point', handleMouseenter)
			map.current.on('mouseleave', 'unclustered-point', handleMouseleave)

			return () => {
				map.current.off('click', 'unclustered-point', handleClick)
				map.current.off('mouseenter', 'unclustered-point', handleMouseenter)
				map.current.off('mouseleave', 'unclustered-point', handleMouseleave)
			}
		},
		[mapOptions.onMarkerClick, isMarkersLoaded]
	)

	useEffect(
		() => {
			if (!isMarkersLoaded || isNull(selectedMarker)) 
				return

			map.current.setFeatureState(
				{ source: 'markers', id: selectedMarker },
				{ selected: true }
			)

			return () => {
				if (!!map.current._removed)
					return

				map.current.setFeatureState(
					{ source: 'markers', id: selectedMarker },
					{ selected: false }
				)
			}
		},
		[selectedMarker, isMarkersLoaded]
	)

	const toGeoJsonFeature = (item, index) => (
		{
			"type": "Feature",
			"id": String(index),
			"geometry": {
				"type": "Point",
				"coordinates": [
					Number(item.address.lng), 
					Number(item.address.lat)
				]
			},
			"properties": {
				position: index + 1,
				...item
			}
		}
	)

	const toGeoJsonFeatureCollection = items => (
		{
			"type": "FeatureCollection",
			"features": items.map((item, index) => toGeoJsonFeature(item, index))
		}
	)

	const panToMarkerItem = id => {
		map.current.panTo(
			map.current.getSource('markers')._options.data.features[id].geometry.coordinates, 
			{ zoom: 14 }
		)
	}

	const getGeoJsonBounds = data => {

		let minLng
		let maxLng
		let minLat
		let maxLat

		data.features.map(({geometry}) => {
			const lngLat = geometry.coordinates
			if (isUndefined(minLng)) {
				minLng = lngLat[0]
			} else if(minLng > lngLat[0]) {
				minLng = lngLat[0]
			}
			if (isUndefined(maxLng)) {
				maxLng = lngLat[0]
			} else if(maxLng < lngLat[0]) {
				maxLng = lngLat[0]
			}
			if (isUndefined(minLat)) {
				minLat = lngLat[1]
			} else if(minLat > lngLat[1]) {
				minLat = lngLat[1]
			}
			if (isUndefined(maxLat)) {
				maxLat = lngLat[1]
			} else if(maxLat < lngLat[1]) {
				maxLat = lngLat[1]
			}
		})

		return [
			maxLng,
			minLat,
			minLng,
			maxLat, 
		]
	}

	const setMapOption = (name, value) => {
		if (!(name in mapOptions))
			return
		
		_setMapOptions(mapOptions => ({
			...mapOptions,
			[name]: value
		}))
	}

	return {
		containerRef,
		map: map.current,
		isMapLoaded,
		mapOptions,
		setMapOption,
		items,
		setItems,
		isMarkersLoaded,
		selectedMarker,
		setSelectedMarker,
		panToMarkerItem
	}
}
