
import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'

export default function Wrapper( { children, className, size, ...props } ) {

	return (
		<div
			className={ classNames(
				'o-wrapper',
				{
					[`o-wrapper--${size}`]: size
				},
				className
			) }
			{ ...props }
		>
			{ children }
		</div>
	)
}

Wrapper.propTypes = {
	size: PropTypes.string
}
