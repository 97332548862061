
/**
 * Global vars
 */
export const SITE_URL = 'https://www.sibylline-escapade.fr' // TODO: use .env var instead?
export const SITE_NAME = 'Sibylline Escapade'

export const TICKET_TAXON = 'sibylline-ticket'
export const PASS_TAXON = 'sibylline-pass'
export const STAY_TAXON = 'sibylline-customized-pass'

export const TWITTER_SITE = 'sibyllinescapad'

export const TEXT_SEPARATOR = ' • '

export const DEFAULT_HERO_IMAGE = '/images/laissez-vous-surprendre.jpg'

export const PICKUP_ADDRESS = {
	firstName: '',
	lastName: '',
	street: '8 avenue du Président Coty',
	postcode: '49240',
	city: 'Avrillé',
	countryCode: 'FR',
	phoneNumber: '02 44 88 93 30',
	company: 'Sibylline Escapade',
	name: 'Adresse du point retrait',
}

export const TRACKING_CODE_LENGTH = 8

/**
 * Inuitcss widths Breakpoint Separator
 */
export const CSS_BREAKPOINT_SEPARATOR = '@'
