import classnames from 'classnames'
import React, { useRef, useState, useEffect } from 'react'

import { isEmpty, isArray, uniqueID } from 'libs/utils'
import { useInterval } from 'libs/interval'

import { Icon } from 'components/media'
import { Subtitle } from 'components/title'
import Layout, { LayoutItem } from 'components/layout'
import { LoaderIcon } from 'components/loader'

export default function Carousel( props ) {

	const {
		title,
		isLoading,
		backgroundColor,
		widths,
		size,
		vAlign,
		isAutoplay,
		speed,
		children,
		className
	} = props

	const carouselId = useRef( uniqueID() )
	const carouselRef = useRef()
	const containerRef = useRef()

	const [hiddenItems, setHiddenItems] = useState( 0 )
	const [stepItems, setStepItems] = useState( 0 )
	const [totalItems, setTotalItems] = useState( 0 )

	const [autoplayDelay, setAutoplayDelay] = useState( null )

	// Init steps
	useEffect(
		() => {
			if ( ! isEmpty( containerRef.current.firstChild ) && ! isEmpty( containerRef.current.firstChild.firstChild ) ) {
				setTotalItems( containerRef.current.firstChild.children.length - stepItems )
				handleResize()
			}
		},
		[containerRef.current, stepItems]
	)

	const handleResize = () => {
		if ( containerRef.current && ! isEmpty( containerRef.current.firstChild ) && ! isEmpty( containerRef.current.firstChild.firstChild ) ) {
			setStepItems( Math.round( containerRef.current.offsetWidth / containerRef.current.firstChild.firstChild.offsetWidth ) )
		}
	}

	// Add resize support
	useEffect(
		() => {
			window.addEventListener( 'resize', handleResize )

			return () => {
				window.removeEventListener( 'resize', handleResize )
			}
		},
		[]
	)

	// Add autoplay pause support
	const getSpeed = () => speed + 900 // Add css transition duration to speed

	useEffect(
		() => {
			if ( carouselRef.current && isAutoplay ) {
				setAutoplayDelay( getSpeed() )

				const handleHover = e => setAutoplayDelay( e.type === 'mouseleave' ? getSpeed() : null )

				carouselRef.current.addEventListener( 'mouseenter', handleHover )
				carouselRef.current.addEventListener( 'mouseleave', handleHover )

				return () => {
					setAutoplayDelay( null )
					carouselRef.current.removeEventListener( 'mouseenter', handleHover )
					carouselRef.current.removeEventListener( 'mouseleave', handleHover )
				}
			}
		},
		[carouselRef.current, isAutoplay]
	)

	// TODO: add touch event support

	const prev = () => setHiddenItems( hiddenItems > stepItems ? hiddenItems - stepItems : 0 )
	const next = () => setHiddenItems( hiddenItems < ( totalItems - stepItems ) ? hiddenItems + stepItems : totalItems )

	const handleClickPrev = () => prev()
	const handleClickNext = () => next()

	// Add autoplay support
	useInterval(
		() => {
			if( hiddenItems < totalItems ) {
				next()
			} else {
				setHiddenItems( 0 )
			}
		},
		autoplayDelay
	)

	if ( isEmpty( children ) && ! isLoading )
		return null

	return (
		<aside
			ref={ carouselRef }
			className={ classnames(
				'c-carousel',
				{
					[`c-carousel--background-color u-background-color--${ backgroundColor } u-rounded`]: backgroundColor
				},
				className
			) }
		>
			<Layout
				isRow
				isFlex
				isAuto
				vAlign="middle"
				className="c-carousel__header"
			>
				<LayoutItem
					isPrimary
					className="c-carousel__title"
				>
					{ !! title && (
						<Subtitle
							size="normal"
						>
							{ title }
						</Subtitle>
					) }
				</LayoutItem>
				<LayoutItem
					className="c-carousel__nav"
				>
					{ isLoading
						? <LoaderIcon/>
						: (
							<>
								<Icon
									title="Précédent"
									name="chevron-left"
									size="tiny"
									isDisabled={ hiddenItems === 0 }
									onClick={ handleClickPrev }
								/>
								<Icon
									title="Suivant"
									name="chevron-right"
									size="tiny"
									isDisabled={ hiddenItems === totalItems }
									onClick={ handleClickNext }
								/>
							</>
						)
					}
				</LayoutItem>
			</Layout>

			<Layout
				className="c-carousel__items"
				size={ size }
			>
				<LayoutItem
					ref={ containerRef }
					className="c-carousel__items-container"
					style={ {
						marginLeft: `${ hiddenItems / stepItems * -100 }%`
					} }
				>
				{ isArray( children )
					? (
						<Layout
							isRow={ !! vAlign }
							isFlex={ !! vAlign }
							isAuto={ !! vAlign }
							vAlign={ vAlign }
							size={ size }
						>
							{ children.map( (child, index) => {
								return (
									<LayoutItem
										key={ `carousel-${ carouselId.current }-item-${ index }` }
										widths={ widths }
									>
										{ child }
									</LayoutItem>
								)
							} ) }
						</Layout>
					)
					: children
				}
				</LayoutItem>
			</Layout>

		</aside>
	)
}

Carousel.defaultProps = {
	widths: {
		mobile: '1/1',
		tablet: '1/2',
		desktop: '1/3',
		'desktop-cart': '1/2'
	},
	speed: 3000
}
