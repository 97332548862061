
import React, { useState, useEffect, useRef } from 'react'
import { ErrorMessage, getIn } from 'formik'
import moment from 'moment'
import { isSameDay, SingleDatePicker } from 'react-dates'
import isBeforeDay from 'react-dates/lib/utils/isBeforeDay'
import isAfterDay from 'react-dates/lib/utils/isAfterDay'

import { nameToId, isEmpty, isArray, isUndefined, resizeFile, isObject } from 'libs/utils'

import { Figure, Icon } from 'components/media'
import { FieldFeedback, FormField, FormControl, Label, Textarea, Input, Password, Select, Toggle, Quantity } from 'components/form/elements'
import Button from 'components/button'
import Layout, { LayoutItem } from 'components/layout'
import { Subtitle } from 'components/title'
import { ContentMarkdown } from 'components/markdown'

export const isRequiredField = (validationSchema, name) => !! getIn( validationSchema.describe().fields, name).tests.find( test => test.name === 'required' )

export function TextareaField( { field, form, ...props } ) {

	const {
		label,
		id,
		size,
		isRequired
	} = props

	return (
		<FormField>
			{ label && (
				<Label
					htmlFor={ id }
					size={ size }
					isRequired={ isRequired }
				>
					{ label }
				</Label>
			) }
			<Textarea
				{ ...field }
				{ ...props }
				hasError={ field && getIn(form.errors, field.name) && getIn(form.touched, field.name) }
			/>
			{ field && <ErrorMessage component={ FieldFeedback } name={ field.name } /> }
		</FormField>
	)
}

export function InputField( { field, form, ...props } ) {

	const {
		label,
		before,
		after,
		innerRef,
		isRequired,
		...attrs
	} = props

	const {
		id,
		size,
	} = attrs

	return (
		<FormField
			hasAddons={ before || after }
			className={ attrs.type === 'hidden' ? 'u-hidden' : null }
		>

			{ label && (
				<Label
				htmlFor={ id }
				size={ size }
				isRequired={ isRequired }
				>
					{ label }
				</Label>
			) }

			{ before && (
				<FormControl>
					{ before }
				</FormControl>
			) }

			<FormControl
				isPrimary
			>
				<Input
					ref={ innerRef }
					{ ...field }
					{ ...attrs }
					hasError={ field && getIn(form.errors, field.name) && getIn(form.touched, field.name) }
				/>
				{ field && <ErrorMessage component={ FieldFeedback } name={ field.name } /> }
			</FormControl>

			{ after && (
				<FormControl>
					{ after }
				</FormControl>
			) }
		</FormField>
	)
}

InputField.defaultProps = {
	type: 'text'
}

export function PasswordField( { field, form, ...props } ) {

	const {
		label,
		before,
		after,
		innerRef,
		isRequired,
		...attrs
	} = props

	const {
		id,
		size,
	} = attrs

	return (
		<FormField
			hasAddons={ before || after }
		>
			{ label && (
				<Label
					htmlFor={ id }
					size={ size }
					isRequired={ isRequired }
				>
					{ label }
				</Label>
			) }

			{ before && (
				<FormControl>
					{ before }
				</FormControl>
			) }

			<FormControl
				isPrimary
			>
				<Password
					innerRef={ innerRef }
					isPrimary={ before || after }
					{ ...field }
					{ ...attrs }
					hasError={ field && getIn(form.errors, field.name) && getIn(form.touched, field.name) }
				/>
				{ field && <ErrorMessage component={ FieldFeedback } name={ field.name } /> }
			</FormControl>

			{ after && (
				<FormControl>
					{ after }
				</FormControl>
			) }
		</FormField>
	)
}

export function SelectField( { field, form, ...props } ) {

	const {
		label,
		isRequired,
		...attrs
	} = props

	const {
		id,
		size,
	} = attrs

	return (
		<FormField>
			{ label && (
				<Label
					htmlFor={ id }
					size={ size }
					isRequired={ isRequired }
				>
					{ label }
				</Label>
			) }
			<Select
				{ ...field }
				{ ...attrs }
				hasError={ field && getIn(form.errors, field.name) && getIn(form.touched, field.name) }
			/>
			{ field && <ErrorMessage component={ FieldFeedback } name={ field.name } /> }
		</FormField>
	)
}

SelectField.defaultProps = {
	children: []
}

export function ToggleField( { field, form, id, ...props } ) {

	let isValue, defaultValue, toggleValue, value, setValue

	if( isUndefined( field ) ) {

		id = id ? id : nameToId( props.name )

		isValue = () => {
			return props.values.indexOf( props.value ) > -1
		}

		defaultValue = () => {
			const valueIndex = props.values.indexOf( props.value )
			return valueIndex > -1 ? props.values[valueIndex] : props.values[0]
		}

		toggleValue = () => {
			const valueIndex = props.values.indexOf( props.value )
			return valueIndex === 0 ? props.values[1] : props.values[0]
		}

		[value, setValue] = useState( defaultValue() )

	} else {

		id = id ? id : nameToId( field.name )

		isValue = () => {
			return props.values.indexOf( field.value ) > -1
		}

		defaultValue = () => {
			const valueIndex = props.values.indexOf( field.value )
			return valueIndex > -1 ? props.values[valueIndex] : props.values[0]
		}

		toggleValue = () => {
			const valueIndex = props.values.indexOf( field.value )
			// console.log('toggleValue', field.value, valueIndex, props.values, valueIndex === 0 ? props.values[1] : props.values[0]);
			return valueIndex === 0 ? props.values[1] : props.values[0]
		}

		const fieldHelpers = form.getFieldHelpers( field.name )
		value = defaultValue()
		setValue = fieldHelpers.setValue
	}

	const handleClick = () => setValue( toggleValue() )

	useEffect( () => {
		return () => {
			if( ! isValue() ) {
				setValue( defaultValue() )
			}
		}
	}, [] )

	return (
		<FormField
			id={ `o-form__field--${ id }` }
		>
			<Toggle
				id={ id }
				value={ value }
				{ ...field }
				{ ...props }
				hasError={ field && form && getIn(form.errors, field.name) && getIn(form.touched, field.name) || props.error }
				onClick={ handleClick }
			/>
			{ field
				? <ErrorMessage component={ FieldFeedback } name={ field.name } />
			 	: <FieldFeedback>{ props.error }</FieldFeedback>
			}
		</FormField>
	)
}

ToggleField.defaultProps = {
	values: [
		false,
		true
	]
}

export function MultipleField( { field, form, ...props } ) {

	const {
		id,
		size,
		label,
		type,
		values,
		names,
		isRequired,
		isStretch,
		isInline
	} = props

	const {
		name
	} = field

	const hasNames = isArray( values ) && isArray( names ) && values.length === names.length

	return (
		<FormField
			isMultiple
			isStretch={ isStretch }
			isInline={ isInline }
		>
			{ label && (
				<Label
					htmlFor={ id }
					size={ size }
					isRequired={ isRequired }
				>
					{ label }
				</Label>
			) }
			{ isArray( values ) && values.map( (value, index) => (
				<FormControl
					key={ index }
				>
					<Input
						id={ `${name}-${index}` }
						{ ...field }
						{ ...props }
						value={ value }
						type={ type }
						// hasError={ field && getIn(form.errors, field.name) && getIn(form.touched, field.name) }
						checked={ isArray( field.value ) ? field.value.indexOf( value ) > -1 : value == field.value }
					/>
					<Label
						htmlFor={ `${name}-${index}` }
					>
						{ hasNames
						 	? names[index]
							: value
						}
					</Label>
				</FormControl>
			) ) }
			{ field && <ErrorMessage component={ FieldFeedback } name={ field.name } /> }
		</FormField>
	)
}

export function RadioField( props ) {
	return (
		<MultipleField
			{ ...props }
			type="radio"
		/>
	)
}

RadioField.defaultProps = {
	values: [
		'yes',
		'no'
	],
	names: [
		'Oui',
		'Non'
	]
}

export function CheckboxField( props ) {
	return (
		<MultipleField
			{ ...props }
			type="checkbox"
		/>
	)
}

CheckboxField.defaultProps = {
	values: [],
	names: []
}


export function QuantityField( { field, form, ...props } ) {

	const {
		label,
		id,
		size,
		isRequired
	} = props

	return (
		<FormField>
			{ label && (
				<Label
					htmlFor={ id }
					size={ size }
					isRequired={ isRequired }
				>
					{ label }
				</Label>
			) }
			<Quantity
				{ ...props }
				{ ...field }
				hasError={ field && getIn(form.errors, field.name) && getIn(form.touched, field.name) }
			/>
			{ field && <ErrorMessage component={ FieldFeedback } name={ field.name } /> }
		</FormField>
	)
}

QuantityField.defaultProps = {
	type: 'text'
}

export function AvatarField( { field, form, imgPlaceholder, placeholder, ...props } ) {

	const {
		label,
		id,
		size,
		isRequired
	} = props

	const [isLoading, setLoading] = useState( false )
	const [thumb, setThumb] = useState( null )
	const [file, setFile] = useState( null )

	const hiddenFileInput = useRef( null )

	const handleClick = () => {
    hiddenFileInput.current.click();
	}

	const handleChange = e => {
		setFile( e.currentTarget.files[0] )
	}

	const handleReset = () => {
		setFile( null )
	}

	useEffect(
		() => {
			const setData = async () => {
				if( file ) {
					setLoading( true )

					const image = await resizeFile( file )
					form.setFieldValue( field.name, image )
					setThumb( image )

					setLoading( false )
				} else {
					form.setFieldValue( field.name, '' )
					setThumb( imgPlaceholder )
				}
			}
			setData()
		},
		[file, imgPlaceholder]
	)

	return (
		<FormField>
			{ label && (
				<Label
					htmlFor={ id }
					size={ size }
					isRequired={ isRequired }
				>
					{ label }
				</Label>
			) }
			<div
				className="o-input u-padding-none u-overflow--hidden"
			>
				<Layout isFlex isRow isAuto size="normal">
					<LayoutItem
						widths={ {
							[null]: '1/4',
							mobile: '1/6',
							tablet: '1/6',
							desktop: '1/8',
							wide: '1/10'
						} }
						className="u-flex-shrink"
					>
						<div className="u-width--p-full" onClick={ handleClick }>
							<Figure
								ratio="1:1"
								image={ ! isLoading && thumb }
								className="u-width--p-full"
							>
								{ isLoading && <Icon name="loader" className="o-figure__image o-ratio__content" /> }
								{ ! isLoading && ! thumb && <Icon name="more" className="o-figure__image o-ratio__content" /> }
							</Figure>
						</div>
					</LayoutItem>
					<LayoutItem
						isPrimary
						isFlex
					>
						<div className="u-flex u-flex-align--center u-width--p-full" onClick={ handleClick }>
							<input
								{ ...props }
								name={ field.name }
								type="file"
								onChange={ handleChange }
								style={ { display: 'none' } }
								ref={ hiddenFileInput }
							/>
							<Subtitle size="normal">
								{ file
									? file.name
									: placeholder
								}
							</Subtitle>
						</div>
					</LayoutItem>
					{ !! field.value && (
						<LayoutItem
							isFlex
						>
							<Button
								isText
								iconLeft="close"
								onClick={ handleReset }
								title="Retirer le fichier"
							/>
						</LayoutItem>
					) }
				</Layout>
			</div>
		</FormField>
	)
}

AvatarField.defaultProps = {
	placeholder: 'Sélectionner un fichier'
}

export function SubmitField( { field, form, ...props } ) {

	const getSizeMinus = () => {
		const sizes = [
			'tiny',
			'small',
			'normal',
			'medium',
			'large',
			'huge'
		]

		const size = props.size ? props.size : 'normal'
		const sizeIndex = sizes.indexOf( size )

		if( sizeIndex > 0 ) {
			return sizes[sizeIndex - 1]
		} else if( sizeIndex > -1 ) {
			return 'tiny'
		}

		return 'small'
	}

	const {
		errors,
		isSubmitting
	} = form

	const {
		id,
		button,
		size,
		type,
		isFullWidth,
		onClick,
		undoOnClick,
		undoHref,
		iconAction,
		disabled,
		isLoading,
		className
	} = props

	return (
		<FormField
			isMultiple
			className={ className }
		>
			<FormControl
				isPrimary
			>
				<Button
					id={ id }
					isLoading={ isLoading || isSubmitting }
					isPrimary
					isFullWidth={ isFullWidth }
					type={ type }
					size={ size }
					disabled={ disabled || isLoading || isSubmitting || ! isEmpty( errors ) }
					onClick={ onClick }
				>
					{ button }
				</Button>
			</FormControl>
			{ iconAction && (
				<FormControl>
					<Icon
						{ ...iconAction }
						size={ getSizeMinus() }
					/>
				</FormControl>
			) }
			{ ( undoHref || undoOnClick ) && (
				<FormControl>
					<Button
						id={ `${id}__undo` }
						size={ getSizeMinus() }
						onClick={ undoOnClick }
						href={ undoHref }
						disabled={ disabled || isLoading || isSubmitting }
					>
						Annuler
					</Button>
				</FormControl>
			) }
		</FormField>
	)
}

SubmitField.defaultProps = {
	button: 'Sauvegarder',
	type: 'submit',
	form: {
		errors: {},
		isSubmitting: false
	}
}

export function DatePickerField( { field, form, ...props } ) {

	const ready = useRef( false )

	const {
		label,
		before,
		after,
		innerRef,
		isRequired,
		blockedDays,
		infos,
		initialVisibleMonth,
		...attrs
	} = props

	const {
		id,
		size,
	} = attrs

	moment.locale( 'fr' )

	const[focused, setFocused] = useState( false )

	const handleDateChange = date => {
		form.setFieldValue( field.name, date ? moment( date ).format('YYYY-MM-DD') : '' )
	}

	const isDayBlocked = day => {

		return blockedDays.filter( rangeOrDate => {
			// not inclusive
			if ( isObject( rangeOrDate ) && ( rangeOrDate.before || rangeOrDate.after ) ) {
				if ( rangeOrDate.before && rangeOrDate.after ) {
					return isBeforeDay( day, rangeOrDate.before ) && isAfterDay( day, rangeOrDate.after )

				} else if ( rangeOrDate.before ) {
					return isBeforeDay( day, rangeOrDate.before )

				} else if ( rangeOrDate.after ) {
					return isAfterDay( day, rangeOrDate.after )
				}

				return false
			}

			return isSameDay( day, rangeOrDate )
		} ).length > 0
	}

	const renderInfos = () => {

		if ( ! infos )
			return null

		return (
			<div className="u-margin-left-normal u-margin-bottom-normal u-margin-right-normal">
				{ <ContentMarkdown isSummary>{ infos }</ContentMarkdown> }
			</div>
		)
	}

	useEffect(
		() => {
			if ( ready.current === true ) {
				if ( ! focused ) {
					form.setFieldTouched( field.name, true )
				}
			}
		},
		[focused]
	)

	useEffect(
		() => {
			ready.current = true
		},
		[]
	)

	return (
		<FormField
			hasAddons={ before || after }
			className={ attrs.type === 'hidden' ? 'u-hidden' : null }
		>

			{ label && (
				<Label
					htmlFor={ id }
					size={ size }
					isRequired={ isRequired }
				>
					{ label }
				</Label>
			) }

			{ before && (
				<FormControl>
					{ before }
				</FormControl>
			) }

			<FormControl
				isPrimary
				hasError={ field && getIn(form.errors, field.name) && getIn(form.touched, field.name) }
			>
				<SingleDatePicker
					id={ !! id ? `${ id }-date-picker` : undefined }
					placeholder="jj/mm/aaaa"
					date={ field.value ? moment( field.value ) : null }
					onDateChange={ handleDateChange }
					focused={ focused }
					onFocusChange={ ({ focused }) => setFocused( focused ) }
					isDayBlocked={ isDayBlocked }
					initialVisibleMonth={ initialVisibleMonth ? () => initialVisibleMonth : undefined }
					withPortal={ true }
					firstDayOfWeek={ 1 }
					numberOfMonths={ 1 }
					renderCalendarInfo={ renderInfos }
					noBorder
					block
				/>
				<Input
					ref={ innerRef }
					{ ...field }
					{ ...attrs }
					type="hidden"
				/>
				{ field && <ErrorMessage component={ FieldFeedback } name={ field.name } /> }
			</FormControl>

			{ after && (
				<FormControl>
					{ after }
				</FormControl>
			) }
		</FormField>
	)
}
