import createClients from "./create-clients"

const config = {
	middlewares: false,
	fake_api: false
}
const store = {
	dispatch: (toDispatch) => {console.log(toDispatch)}
}
const params = {}
const offline = true

let ApiClient;

const getApiClient = () => {
	if (!ApiClient) ApiClient = createClients(config, store).ApiClient

	return ApiClient;
}

export default {

	new: () => getApiClient().post( '/carts', {params, offline} ),

	get: token => getApiClient().get( `/carts/${token}`, {params, offline} ),

	addItem: ( token, item ) => getApiClient().post( `/carts/${token}/items`, {params: item, offline} ),

	addItems: ( token, items ) => getApiClient().post( `/carts/${token}/multiple-items`, {params: items, offline} ),

	updateItemQuantity: ( token, identifier, quantity ) => {
		if( quantity === 0 ) {
			return getApiClient().destroy( `/carts/${token}/items`, identifier, {params, offline} )
		} else {
			params.quantity = quantity
			return getApiClient().put( `/carts/${token}/items`, identifier, {params, offline} )
		}
	},

	addCoupon: ( token, coupon ) => getApiClient().put( `/carts/${token}`, 'coupon', { params: { coupon }, offline } ),

	removeCoupon: ( token, coupon ) => getApiClient().destroy( `/carts/${token}/coupon`, coupon, { params, offline } )

}
