
import classNames from 'classnames'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'

import Layout, { LayoutItem } from 'components/layout'
import Wrapper from 'components/wrapper'
import { Content } from 'components/generic'
import { Navbar, Menu } from 'components/navigation'

import { isEmpty, isObject } from 'libs/utils'
import { useIntersect, changeHashWithoutScrolling } from 'libs/navigation'

export default function Section( { children, className, size, ...props } ) {

	const router = useRouter()
	const [active, setActive] = useState( false )
	const [navReady, setNavReady] = useState( false )
	const [ref, entry] = useIntersect( { rootMargin: '-29% 0px -70% 0px' } )

	// Nav is ready when route change is complete
	const handleRouteChangeComplete = () => setNavReady( true )

	// Disbabled nav when route change start to prevent router override
	const handleRouteChangeStart = () => setNavReady( false )

	// Init router events
	useEffect(
		() => {
			if( ! navReady ) {
				router.events.on('routeChangeComplete', handleRouteChangeComplete)
				router.events.on('routeChangeStart', handleRouteChangeStart)
				handleRouteChangeComplete()
			}
			return () => {
				router.events.off('routeChangeComplete', handleRouteChangeComplete)
				router.events.off('routeChangeStart', handleRouteChangeStart)
			}
		},
		[]
	)

	// Set hash navigation
	useEffect(
		() => {
			if( navReady && entry.intersectionRatio > 0 ) {
				changeHashWithoutScrolling( props.id )
				setActive( true )
			} else {
				setActive( false )
			}
		},
		[navReady, entry]
	)

	return (
		<div
			className={ classNames(
				'o-section',
				{
					[`o-section--${size}`]: size,
					'o-section--active': active
				},
				className
			) }
			{ ...props }
			ref={ ref }
		>
			{ children }
		</div>
	)
}

export function SectionLayout( { children, isFlush, id, navbar, ...props } ) {

	return (
		<Section
			id={ id }
			size={ !! isFlush && 'flush' }
		>
			{ isObject( navbar ) && (
				<SectionNavbar
					meta={ navbar.meta }
				>
					{ navbar.title }
				</SectionNavbar>
			) }
			<Wrapper>
				<Layout { ...props }>

					{ children }

				</Layout>
			</Wrapper>
		</Section>
	)
}

export function SectionNavbar( props ) {

	const {
		meta,
		children
	} = props

	return (
		<Navbar
			fixed="top"
			background="white"
			vAlign="middle"
			className="o-section__navbar"
			zIndex={ 90 }
		>
			<LayoutItem
				isPrimary
			>
				{ children }
			</LayoutItem>
			{ !! meta && (
				<LayoutItem
					align="end"
				>
					{ meta }
				</LayoutItem>
			) }
		</Navbar>
	)
}

export function SectionHeader( props ) {

	const {
		className,
		widths,
		push,
		pull,
		children
	} = props

	return (
		<LayoutItem
			className={ classNames( 
				'u-position-sticky@tablet u-top@tablet u-position-static@tablet-cart',
				className
			) }
			widths={ widths }
			push={ push }
			pull={ pull }
		>
			<Content
				isPost
				className="u-margin-vertical u-margin-left u-margin-right u-margin-right-none@tablet u-margin-right@tablet-cart"
			>
				{ children }
			</Content>
		</LayoutItem>
	)
}

SectionHeader.defaultProps = {
	widths: {
		'tablet': '1/3',
		'tablet-cart': '1/1',
	}
}

export function SectionContent( props ) {

	const {
		isSummary,
		isPost,
		isFullHeight,
		vAlign,
		align,
		widths,
		push,
		pull,
		children,
		className
	} = props

	return (
		<LayoutItem
			align={ align }
			widths={ widths }
			push={ push }
			pull={ pull }
			className={ className }
		>
			<div className={ classNames(
				'o-wrapper u-padding-top',
				{
					'u-flex-direction--column u-min-height--full': isFullHeight,
					'u-flex-justify--center': isFullHeight && ['center', 'middle'].indexOf( vAlign ) > -1,
					'u-flex-justify--end': isFullHeight && ['end', 'bottom'].indexOf( vAlign ) > -1
				}
			) }
			>

				{ isSummary || isPost ? (

					<Content
						isSummary={ isSummary }
						isPost={ isPost }
					>
						{ children }
					</Content>

				) : (
					children
				) }

			</div>
		</LayoutItem>
	)
}

SectionContent.defaultProps = {
	widths: {
		'tablet': '2/3',
		'tablet-cart': '1/1',
	}
}
