
import { isString } from 'utils'

export function html( string ) {

	// Add nonbreaking space before each double signs
	string = addActions( string )

	return string
}

export function text( string ) {

	// Add nonbreaking space before each double signs
	string = addActions( string )

	return string
}

/**
 * Set all actions here
 */
function addActions( string ) {

	// string = addExp( string )
	string = addNbsp( string )

	return string
}

/**
 * Add exposants
 */
function addExp( string ) {
	if( isString( string ) ) {
		string = string.replace( /([0-9]+)([a-z]{2,3})(\s?)/, '$1<sup>$2</sup>$3' )
	}
	return string
}

/**
 * Add nonbreaking space before each double signs
 */
function addNbsp( string ) {
	if( isString( string ) ) {
		string = string.replace( /\s([?|!|%|€|*])/, '\xa0$1' )
	}
	return string
}

/**
 * @see https://gomakethings.com/converting-a-string-into-markup-with-vanilla-js/
 */
