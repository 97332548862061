
import classNames from 'classnames'

import Button from 'components/button'

import { isEmpty, uniqueID, isObject } from 'libs/utils'

export default function Tabs( props ) {

	const {
		isFullWidth,
		active,
		size,
		color,
		onClick,
		isLoading,
		className
	} = props

	let {
		items
	} = props

	if( isEmpty( items ) )
		return null

	let indexKey = false

	if( isObject( items ) ) {
		items = Object.entries( items )
		indexKey = true
	}

	return (
		<nav
			className={ classNames(
				'o-tabs',
				{
					'o-tabs--full-width': isFullWidth
				},
				className
			) }
		>
			<ul>
				{ items.map( (item, index) => {

					const key = indexKey ? item[0] : index
					const name = indexKey ? item[1] : item

					return (
						<li
							key={ key }
							className={ classNames(
								'o-tab',
								{
									'o-tab--active': active === key
								}
							) }
						>
							<Button
								id={ `tab-${key}-${uniqueID()}` }
								onClick={ e => onClick( key, name, e ) }
								isPrimary={ active === key }
								color={ color }
								size={ size }
								isLoading={ isLoading === key }
								disabled={ !! isLoading && isLoading !== key }
							>
								{ name }
							</Button>
						</li>
					)
				} ) }
			</ul>
		</nav>
	)
}

Tabs.defaultProps = {
	items: [],
	onClick: () => null,
	color: 'dark-gray'
}
