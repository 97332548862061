import Button from "components/button"
import { Content } from "components/generic"
import Layout, { LayoutItem } from "components/layout"
import { ContentMarkdown } from "components/markdown"
import { Figure } from "components/media"
import Section from "components/section"
import Wrapper from "components/wrapper"

const parseYoutubeUrl = (text = "") => {
	const youtubeRegexp = /^https?:\/\/(?:w{3}\.)?youtu\.?be.+$/gm
	const youtubeUrl = text.match(youtubeRegexp)

	if (youtubeUrl) {
		return [
			text.replace(youtubeRegexp, ""),
			youtubeUrl[0]
		]
	}

	return [
		text,
		null
	]
}

export function StayCard(props) {

	const {
		ancestors,
		title,
		image,
		slug,
		index
	} = props

	const [
		description,
		youtubeUrl
	] = parseYoutubeUrl(props.description)
	const anchor = index === 0 ? "decouvrir" : slug.replace(`${ancestors[0].slug}/`, "")
	const hasMedia = youtubeUrl || image
	const isReverse = hasMedia ? index % 2 === 0 : false
	const pushOrPull = isReverse ? "pull" : "push"
	const button = props.button?.split("|")

	return (
		<Section
			id={anchor}
		>
			<Wrapper className={index === 0 ? "u-padding-top" : undefined}>
				<Layout
					isFlex
					isReverse={isReverse}
				>
					{hasMedia && (
						<LayoutItem
							widths={{
								'tablet': '1/2',
								'tablet-cart': '1/1',
								'desktop': '2/5',
								'desktop-cart': '1/2'
							}}
							{...{
								[pushOrPull]: {
									'desktop': '1/10',
									'desktop-cart': 'none'
								}
							}}
							className="u-margin-bottom"
						>
							{youtubeUrl
								? (
									<Figure
										isRounded
										isGrow
										ratio="16:9"
									>
										<iframe
											src={youtubeUrl}
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											loading="lazy"
											allowfullscreen
										/>
									</Figure>
								)
								: (
									<Figure
										isRounded
										isGrow
										ratio="3:2"
										image={image}
									/>
								)
							}
						</LayoutItem>
					)}
					<LayoutItem
						widths={{
							'tablet': '1/2',
							'tablet-cart': '1/1',
							'desktop': '2/5',
							'desktop-cart': '1/2'
						}}
						{...{
							[pushOrPull]: hasMedia
								? {
									'desktop': '1/10',
									'desktop-cart': 'none'
								}
								: {
									'tablet': '1/4',
									'tablet-cart': 'none',
									'desktop': '3/10',
									'desktop-cart': '1/4'
								}
						}}
						className="u-margin-bottom"
					>
						<ContentMarkdown
							isPost
							className="u-margin-horizontal u-margin-horizontal-none@tablet u-text-align--justify"
						>
							{`## ${title} 

${description}`}
						</ContentMarkdown>
						{!!button && (
							<Content
								isPost
								className="u-margin-horizontal u-margin-horizontal-none@tablet u-text-align--justify"
							>
								<Button
									href={button[0]}
									isPrimary
									isFullWidth
									className="u-margin-top"
								>
									{button[1]}
								</Button>
							</Content>
						)}
					</LayoutItem>
				</Layout>
			</Wrapper>
		</Section>
	)
}
