
import classNames from 'classnames'
import React, { createElement } from 'react'
import PropTypes from 'prop-types'

import { text } from 'libs/text-manipulation'
import { isArray } from 'libs/utils'

export default function Title( props ) {

	const {
		isSub,
		color,
		align,
		size,
		tag,
		className,
		children
	} = props;

	const type = isSub ? 'subtitle' : 'title'

	let classes = classNames(
		`o-${type}`,
		{
			[`o-${type}--${size}`]: size,
			[`u-color--${color}`]: color,
			[`u-text-align--${align}`]: align
		},
		className
	)

	return createElement(
		tag,
		{
			className: classes
		},
		isArray( children )
			? children.map( text ) // TODO: Make it recursive
			: text( children )
	)
}

Title.propTypes = {
	isSub: PropTypes.bool,
	color: PropTypes.string,
	size: PropTypes.string,
	tag: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.node.isRequired
}

Title.defaultProps = {
  tag: 'p',
}

export function Subtitle( props ) {

	const subProps = Object.assign(
		{},
		props,
		{
			isSub: true
		}
	)

	return <Title {...subProps} />
}
