import { useMap } from "contexts/MapContext"

const Map = props => {

	const {
		id,
		className
	} = props

	const {
		containerRef,
	} = useMap()

	return (
		<div 
			ref={ containerRef } 
			id={ id }
			className={ className } 
		/>
	)
}

export default Map
