import React, { createContext, useContext, useState, useEffect} from 'react'
import cartClient from 'clients/cart-client'
import { useRouter } from 'next/router'
import cookies from 'next-cookies'
import { useAlert } from 'react-alert'

import { ErrorMarkdown } from 'components/markdown'

import { isServerSide, isEmpty, responseCallback, isNumber, isNull, isFormatTicket, getAudienceCode, getVariantStock, getProductQuantityParams } from '../libs/utils'

const cartContextDefaultValues = {
	isCartEmpty: true,
	isCheckout: false,
	isOpen: false,
	isLoading: false,
	isOrdering: false,
	token: undefined,
	cart: undefined ,
	setCart: cart => null,
	addItem: item => false,
	updateItemQuantity: item => false,
	getProductItems: product => [],
	hasItemMaxQuantity: (product, variant) => false,
	getItemQuantityLeft: (product, variant) => false,
	getCartQuantity: () => false,
	addCoupon: coupon => false,
	removeCoupon: coupon => false,
	toggleFormat: item => null,
	goToCheckout: () => null,
	open: () => null,
	close: () => null,
	toggle: () => null,
	check: forced => null,
	clear: () => null
}

const CartContext = createContext( cartContextDefaultValues )

export const ProvideCart = ( { children, data } ) => {
	const dataWrapper = useProvideCart( data.token, data.cart )

	return (
		<CartContext.Provider value={dataWrapper}>
			{children}
		</CartContext.Provider>
	)
}

// Hook for child components to get the Cart object ...
// ... and re-render when it changes.
export const useCart = () => {
	return useContext(CartContext)
}

// Provider hook that creates Data object and handles state
const useProvideCart = (t, c) => {

	const alert = useAlert()
	const router = useRouter()
	const isCheckout = false
	const [isOrdering, setIsOrdering] = useState( false )
	const [isLoading, setIsLoading] = useState( false )
	const [isOpen, setIsOpen] = useState( false )
	const [token, setToken] = useState( t )
	const [cart, setCart] = useState(! isNull( c ) && ('code' in c) ? null : c )

	const getToken = async () => {
		const response = await cartClient.new()

		responseCallback(
			response,
			response => {
				setCart( response )
			}
		)
	}

	const storeToken = token => {
		document.cookie = `cartToken =${token}; path =/`
	}

	const checkCart = async forced => {
		if( ! token ) {
			return await clearCartContext()

		} else if( token && ! cart || forced ) {
			const response = await cartClient.get( token )

			responseCallback(
				response,
				response => {
					setCart( response )
				},
				async error => await clearCartContext()
			)

			return response
		}
	}

	const clearCartContext = async () => {
		// Create a new cart
		return await getToken()
	}

	const addItem = async item => {
		setIsLoading( `addItem-${ item.variantCode || item.productCode }` )
		const response = await cartClient.addItem( token, item )

		responseCallback(
			response,
			response => {
				setCart(response)
				setIsOpen(true)
				setIsLoading(false)
			},
			error => setIsLoading(false)
		)

		return response
	}

	const updateItemQuantity = async ( identifier, quantity ) => {
		setIsLoading( `updateItemQuantity_${identifier}` )
		const response = await cartClient.updateItemQuantity( token, identifier, quantity )

		responseCallback(
			response,
			response => {
				setCart(response)
				setIsLoading(false)
			},
			error => setIsLoading(false)
		)

		return response
	}

	const getItem = ( productCode, variantCode ) => {
		if( isEmpty( cart ) || ! productCode || ! variantCode )
			return null

		return cart.items.filter( item => item.product.code === productCode && item.product.variants.filter( variant => variant.code === variantCode ).length === 1 )[0]
	}

	const getProductItems = product => {
		if( isEmpty( cart ) || ! product )
			return []

		return cart.items.filter( item => item.product.code === product.code )
	}

	const getItemQuantityLeft = ( product, variant ) => {
		const item = getItem( product.code, variant.code ) || { quantity: 0 }	
		const quantityParams = getProductQuantityParams( product )

		let stockLeft = getVariantStock( variant ) 

		if ( !! quantityParams && quantityParams.max < stockLeft ) {
			stockLeft = quantityParams.max
		}

		if ( product.hasSharedStock ) {
			getProductItems( product ).forEach( i => {
				if ( i.product.variants[0].code !== variant.code ) {
					stockLeft -= i.quantity
				}
			} )
		}

		return stockLeft - item.quantity
	}

	const hasItemMaxQuantity = ( product, variant ) => {
		return getItemQuantityLeft( product, variant ) <= 0
	}

	const getCartQuantity = () => {
		if( ! isEmpty( cart ) && ! isEmpty( cart.items ) ) {
			return cart.items.map( item => item.quantity ).reduce( (accumulator, currentValue) => accumulator + currentValue )
		}

		return 0
	}

	const addCoupon = async coupon => {
		setIsLoading( 'addCoupon' )
		const response = await cartClient.addCoupon( token, coupon )

		responseCallback(
			response,
			response => {
				setCart(response)
				setIsLoading(false)
			},
			error => setIsLoading(false)
		)

		return response
	}

	const removeCoupon = async coupon => {
		setIsLoading( `removeCoupon_${coupon}` )
		const response = await cartClient.removeCoupon( token, coupon )

		responseCallback(
			response,
			response => {
				setCart(response)
				setIsLoading(false)
			},
			error => setIsLoading(false)
		)

		return response
	}

	const toggleFormat = async item => {
		const variant = item.product.variants[0]

		if( ! variant )
			return null

		setIsLoading( `toggleFormat_${item.id}` )
		
		// To get a variant, we need to pass all options setted on
		const options = {}

		// Maybe there's a better way to format options, I think, I feel it, I'm sure of it!
		Object.entries( variant.nameAxis ).forEach( ([key, value]) => {
			switch( key ) {
				case 'audiences':
					options[key] = getAudienceCode( variant.nameAxis.audiences )
					break
				case 'format':
					options[key] = isFormatTicket( variant ) ? 'eticket' : 'ticket'
					break
				case 'number_of_activities':
					options[key] = Number( value ) > 1 ? `${ value }_activities` : `${ value }_activity`
					break
				case 'number_of_meals':
					options[key] = Number( value ) > 1 ? `${ value }_meals` : `${ value }_meal`
					break
				case 'number_of_nights':
					options[key] = Number( value ) > 1 ? `${ value }_nights` : `${ value }_night`
					break
				case 'number_of_participants':
					options[key] = Number( value ) > 1 ? `${ value }_participants` : `${ value }_participant`
					break
				default:
					options[key] = ! isNaN( value ) ? Number( value ) : value
					break
			}
		} )

		const addResponse = await cartClient.addItem( token, {
			productCode: item.product.code,
			quantity: item.quantity,
			options
		} )

		responseCallback(
			addResponse,
			async response => {
				const removeResponse = await cartClient.updateItemQuantity( token, item.id, 0 )

				responseCallback(
					removeResponse,
					response => {
						setCart(response)
						setIsLoading(false)
					},
					error => {
						alert.error( <ErrorMarkdown message="Un erreur est survenue lors de la modification du produit." error={ error }/> )
						setIsLoading(false)
						checkCart( true )
					}
				)
			},
			error => {
				alert.error( <ErrorMarkdown message="Un erreur est survenue lors de la modification du produit." error={ error }/> )
				setIsLoading(false)
			}
		)
	}


	const goToCheckout = () => {
		setIsOrdering(true)
		setIsOpen(false)

		router.events.on('routeChangeComplete', handleRouteChangeComplete )
		router.push('/commande')
	}

	const handleRouteChangeComplete = url => {
		setIsOrdering(false)

		router.events.off('routeChangeComplete', handleRouteChangeComplete )
	}

	useEffect(
		() => {
			storeToken( token )
			checkCart()
		},
		[token]
	)

	useEffect(
		() => {
			if( cart && cart.tokenValue && cart.tokenValue !== token ) {
				setToken( cart.tokenValue )
			}
		},
		[cart]
	)


	/**
	 * Display functions
	 */

	const open = () => {
		setIsOpen( true )
	}

	const close = () => {
		setIsOpen( false )
	}

	const toggle = () => {
		if( isOpen ) {
			close()
		} else {
			open()
		}
	}

	return {
		isCartEmpty: isNull( cart ) || isEmpty( cart.items ),
		isCheckout,
		isOpen,
		isLoading,
		isOrdering,
		token,
		cart,
		setCart,
		addItem,
		getProductItems,
		updateItemQuantity,
		hasItemMaxQuantity,
		getItemQuantityLeft,
		getCartQuantity,
		addCoupon,
		removeCoupon,
		toggleFormat,
		goToCheckout,
		open,
		close,
		toggle,
		check: checkCart,
		clear: clearCartContext
	}
}

export async function getCartData( appContext ) {
	let t

	if( isServerSide() ){
		t = cookies( appContext.ctx ).cartToken || null
	} else {
		t = cookies( appContext ).cartToken || null
	}

	let c = null
	if( t != null ) {
		c = await cartClient.get( t )
	}

	return {
		token : t,
		cart : c
	}
}
