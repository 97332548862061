import classNames from 'classnames'
import Link from 'next/link'

import Button from 'components/button'
import { Figure } from 'components/media'

import { CSS_BREAKPOINT_SEPARATOR } from 'libs/variables'

import { getClassWidths, isExternal as fIsExternal, isUndefined } from 'libs/utils'
import { forwardRef } from 'react'

// inuitcss vars
const cardBpName = 'mobile'

const Card = ( props, ref ) => {

	const {
		id,
		layout,
		widths,
		push,
		pull,
		image,
		ratio,
		size,
		hasOverlay,
		hasDivider,
		isFullHeight,
		isStretch,
		isLoading,
		href,
		as,
		isExternal,
		onClick,
		button,
		meta,
		theme,
		className,
		style,
		children
	} = props

	let {
		hasBorder
	} = props

	if( isUndefined( hasBorder ) ) {
		hasBorder = true
	}

	const defaultRatio = layout === '1/1' ? '2:1' : '1:1'
	const overlay = hasOverlay ? ( props.overlay ? props.overlay : 'primary' ) : null

	let content = (
		<>
			{ image && (
				<Figure
					hasOverlay={ hasOverlay }
					overlay={ overlay }
					image={ image }
					ratio={ ratio || ( ! hasOverlay ? defaultRatio : null ) }
				/>
			) }

			{ children }

			{/* containsComponent( children, 'CardContent' )
		 		? children
				: (
					<CardContent size={ size }>
						{ children }
					</CardContent>
				)
			*/}

			{ ( button || meta ) && (
				<CardFooter
					size={ size }
					className="c-card__meta"
				>
					{ meta && (
						<aside className="c-card__meta-value">{ meta }</aside>
					) }
					{ button && (
						<Button
							onClick={ onClick }
							color={ ! hasOverlay ? 'primary': null }
							size={ ! hasOverlay ? 'small' : null }
							isLoading={ isLoading }
						>
							{ button }
						</Button>
					) }
				</CardFooter>
			) }
		</>
	)

	const containerClasses = classNames(
		'c-card__container',
		'u-rounded'
	)

	if( href ) {
		content = (
			fIsExternal( href ) || isExternal
			? (
				<a href={ href } target="_blank" className={ containerClasses }>
					{ content }
				</a>
			)
			: (
				<Link href={ href } as={ as }>
					<a className={ containerClasses }>
						{ content }
					</a>
				</Link>
			)
		)
	} else {
		content = (
			<div className={ containerClasses }>
				{ content }
			</div>
		)
	}

	return (
		<article
			ref={ ref }
			id={ id }
			className={ classNames(
				'c-card',
				{
					'c-card--image': image,
					'c-card--link': href,
					'c-card--overlay': hasOverlay,
					'c-card--border': hasBorder && ! hasOverlay,
					'c-card--divider': hasDivider,
					'c-card--large': layout === '1/1',
					'c-card--stretch': isStretch,
					[`c-card--${theme}`]: theme,
					'o-layout__item': layout || widths,
					[`u-${layout}${CSS_BREAKPOINT_SEPARATOR}${cardBpName}`]: layout,
					'u-rounded': ! layout,
					[`u-height--full${CSS_BREAKPOINT_SEPARATOR}desktop`]: isFullHeight
				},
				getClassWidths( widths ),
				getClassWidths( push, 'push' ),
				getClassWidths( pull, 'pull' ),
				className
			) }
			style={ style }
		>
			{ content }
		</article>
	)
}

export default forwardRef( Card )

export const CardHeader = forwardRef( (props, ref) => {

	const {
		size,
		isPrimary,
		children,
		className
	} = props

	return (
		<div
			ref={ ref }
			className={ classNames(
				"c-card__header o-box u-content u-content--post",
				{
					[`o-box--${size}`]: size,
					'u-flex-grow u-flex-direction--column': isPrimary
				},
				className
			) }
		>
			{ children }
		</div>
	)
} )

export const CardContent = forwardRef( (props, ref) => {

	const {
		size,
		isPrimary,
		isScrollable,
		isCentered,
		align,
		children,
		className
	} = props

	const scrollableClasses = {
		'o-box': ! size,
		[`o-box o-box--${size}`]: size,
		'u-flex-direction--column': isPrimary || isCentered || align,
		'u-flex-grow': isPrimary,
		'u-flex-justify--center': isCentered,
		[`u-flex-justify--${align}`]: align
	}

	return (
		<div
			ref={ ref }
			className={ classNames(
				"c-card__content u-content u-content--summary",
				{
					'u-flex-direction--column': isPrimary,
					'u-flex-grow': isPrimary
				},
				(
					! isScrollable
					? scrollableClasses
					: 'c-card__content--scrollable'
				),
				className
			) }
		>
			{ isScrollable
				? (
					<div
						className={ classNames(
							'c-card__scrollable-container',
							scrollableClasses
						) }
					>
						{ children }
					</div>
				)
				: children
			}
		</div>
	)
} )

export const CardFooter = forwardRef( (props, ref) => {

	const {
		size,
		isPrimary,
		children,
		align,
		className
	} = props

	return (
		<div
			ref={ ref }
			className={ classNames(
				"c-card__footer o-box",
				{
					[`o-box--${size}`]: size,
					[`u-text-align--${align}`]: align,
					'u-flex-grow u-flex-direction--column': isPrimary
				},
				className
			) }
		>
			{ children }
		</div>
	)
} )
