
import classNames from 'classnames'
import React, { createElement, forwardRef } from 'react'
import PropTypes from 'prop-types'

import Card, { CardHeader, CardContent, CardFooter } from 'components/card'
import { PostCard } from 'components/generic'

import { isEmpty, getClassWidths } from 'libs/utils'

export function GridCard( { header, content, footer, ...props } ) {

	const {
		size
	} = props

	return (
		<Card
			{ ...props }
		>
			{ !! header && (
				<CardHeader
					size={ size }
				>
					{ header }
				</CardHeader>
			) }
			{ !! content && (
				<CardContent
					size={ size }
				>
					{ content }
				</CardContent>
			) }
			{ !! footer && (
				<CardFooter
					size={ size }
				>
					{ footer }
				</CardFooter>
			) }
		</Card>
	)
}

export function Grid( props ) {

	const {
		card: Card = PostCard,
		items = []
	} = props

	if( isEmpty( items ) )
		return null

	return (
		<Layout isFlex>
			{ items.map( ( item, index ) => (
				<Card key={ `grid-item-${ index }` } { ...item } index={index} />
			) ) }
		</Layout>
	)
}

const Layout = forwardRef( ( props, ref ) => {

	const {
		id,
		isFlex,
		hasFlexItems,
		isColumn,
		isRow,
		isRowMobile,
		isReverse,
		isAuto,
		size,
		align,
		vAlign,
		children,
		className,
		...attrs
	} = props

	return (
		<div
			ref={ ref }
			id={ id }
			className={ classNames(
				'o-layout',
				{
					'o-layout--flex': isFlex || isRow,
					'o-layout--column': isColumn,
					'o-layout--row': isRow,
					'o-layout--row-mobile': isRowMobile,
					'o-layout--auto': isAuto,
					'o-layout--reverse': isReverse,
					'o-layout--flex-items': hasFlexItems,
					[`o-layout--${size}`]: size,
					[`o-layout--${align}`]: align,
					[`o-layout--${vAlign}`]: vAlign,
				},
				className
			) }
			{ ...attrs }
		>
			{ children }
		</div>
	)
} )

export default Layout

export const LayoutItem = forwardRef( ( props, ref ) => {

	const {
		isFlex,
		isPrimary,
		align,
		widths,
		push,
		pull,
		children,
		className,
		...attrs
	} = props

	return (
		<div
			ref={ ref }
			className={ classNames(
				'o-layout__item',
				{
					[`o-layout__item--${align}`]: align,
					'o-layout__item--primary': isPrimary,
					'u-flex': isFlex
				},
				getClassWidths( widths ),
				getClassWidths( push, 'push' ),
				getClassWidths( pull, 'pull' ),
				className
			) }
			{ ...attrs }
		>
			{ children }
		</div>
	)
} )

LayoutItem.propTypes = {
	widths: PropTypes.oneOfType( [
		PropTypes.object,
		PropTypes.string
	] )
}
