import classNames from "classnames"

const Marker = props => {

	const {
		isSelected,
		isHoverable,
		isHover,
		onClick,
		children
	} = props

	return (
		<button 
			className={ classNames(
				"o-marker",
				{
					"o-marker--selected": isSelected,
					"o-marker--hoverable": isHoverable,
					"o-marker--hover": isHover,
				}
			) }
			type="button"
			onClick={ onClick }
		>
			{ children }
		</button>
	)
}

export default Marker
