
import Logger from 'js-logger'
import fetch from 'isomorphic-fetch'
import * as FormData from 'form-data'

import { isServerSide } from '../libs/utils'
import Cookies from 'js-cookie'
import cookies from 'next-cookies'

export default function ApiClient(config) {
	function call(method, endpoint, params, callback, baseParams, log=true) {

		if (config.middlewares && config.middlewares.endpoints) {
			[ method, endpoint, params ] = config.middlewares.endpoints([ method, endpoint, params ])
		}

		var headers = {}

		if (!(params instanceof FormData)) {
			headers['Content-Type'] = 'application/json'
		}

		// Are we spoofing a user to fix their issues
		if(process.env.APP_TOKEN){
			headers['Authorization'] = 'Bearer ' + process.env.APP_TOKEN
		}
		// or are we logged in
		if ( isServerSide() && config.ctx && cookies( config.ctx ).userToken ) {
			headers['Authorization'] = 'Bearer ' + cookies( config.ctx ).userToken
		}

		if (Cookies.get('userToken')) {
			headers['Authorization'] = 'Bearer ' + Cookies.get('userToken')
		}

		var fetchParams = {
			method: method.toUpperCase(),
			headers: headers,
		}

		switch(method) {
			case "post":
			case "put":
			case "patch":
				if (!(params instanceof FormData)) {
					fetchParams.body = JSON.stringify(params)
				} else {
					fetchParams.body = params
				}
				break
			case "get":
				var keys = Object.keys(params)
				if (keys.length > 0) {
					for(var paramIndex in keys) {
						endpoint += (paramIndex == 0 ? "?" : "&")
						endpoint += keys[paramIndex] + "=" + params[keys[paramIndex]]
					}
				}
				break
			default:
				break
		}

		if (log) {
			Logger.debug({
				method: method,
				request: endpoint,
				params: baseParams ? baseParams : params,
				headers: headers,
				data: baseParams ? baseParams : params,
			})
		}

		return fetchWrapper( getCorrectUrl() + endpoint, fetchParams, config)
		.then(response => {
			if (response.status === 204) {
				return [ {}, response.headers ]
			}

			return response.json()
				.then(json => [ json, response.headers ])
		})
		// Go through middlewares if defined in config
		.then(data => {
			if (!(config.middlewares && config.middlewares.apiClient)) {
				return data
			}

			return config.middlewares.apiClient(data)
		})
		.then(([ response, headers ]) => {
			if (headers.get("X-Next-Page") !== null) {
				response = {
					list: response,
					pagination: {
						next: Number(headers.get("X-Next-Page")),
						previous: Number(headers.get("X-Prev-Page")),
						total: Number(headers.get("X-Total")),
						totalPages: Number(headers.get("X-Total-Pages")),
						perPage: Number(headers.get("X-Per-Page"))
					}
				}
			}

			if (log) {
				Logger.debug({
					method: method,
					response: endpoint,
					data: response,
					headers: headers
				})
			}

			if (callback) {
				return callback(response)
			}

			return response
		}).catch(exception => {
			Logger.debug({
				method: method,
				response: endpoint,
				exception: exception
			})

			const error = { error: true, message: exception.message }
			if (callback) callback(error)

			return error
		})
	}

	function post(endpoint, { params = {},  defaultParams = false}) {
		const ps = new FormData()
		const keys = Object.keys(params)
		let formData = false

		for (let i in keys) {
			let key = keys[i]

			if (key == "attachments") {
				formData=true
				for (let j in params[key]) {
					ps.append("attachments[]", params[key][j])
				}
				continue
			}

			if (key == "attachment") {
				formData=true
				ps.append("attachment", params[key])

				continue
			}

			ps.append(key, (params[key] instanceof Object) ? JSON.stringify(params[key]) : params[key])
		}
		return call("post", endpoint, params, undefined, defaultParams, true)
	}

	function get(endpoint, { params = {}}) {
		return call("get", endpoint, params, undefined, null, true)
	}

	function put(endpoint, id, { params = {}=true , defaultParams = false, log = true}) {
		var ps = new FormData()
		var keys = Object.keys(params), key
		var formData = false
		for (var i in keys) {
			key = keys[i]
			if (key == "attachments") {
				formData=true
				if (params[key].length > 0) {
					for (var j in params[key]) {
						ps.append("attachments[]", params[key][j]["id"] ? JSON.stringify(params[key][j]) : params[key][j])
					}
				} else {
					ps.append("attachments[]", params[key])
				}
			} else if (key == "attachment") {
				formData=true
				ps.append("attachment", params[key])
			} else {
				ps.append(key, (params[key] instanceof Object) ? JSON.stringify(params[key]) : params[key])
			}
		}

		return call("put", endpoint + (id ? ("/" + id) : ""), formData ? ps : params, undefined, defaultParams, true)
	}

	function patch(endpoint, id=true) {
		return call("patch", endpoint + (id ? ("/" + id) : ""), params, undefined, null, true)
	}

	function destroy(endpoint, id=true, { params = {}}) {
		return call("delete", endpoint + "/" + id, params, undefined, null, true)
	}

	function upload(endpoint, fieldName, file, callback=true) {
		var params = new FormData();
		params.append(fieldName, file)
		return call("put", endpoint, params, callback, undefined, null, true)
	}

	/**
	 * Defined used url for fetch
	 */
	function getCorrectUrl(){
		return isServerSide() ? process.env.API_URL_BRIDGE : process.env.API_URL
	}

	return {
		get,
		post,
		put,
		patch,
		destroy,
		upload,
	}
}

function fetchWrapper(endpoint, params, config) {
	if (config.fake_api) {
		return config.fake_api(endpoint, params)
	}

	return fetch(endpoint, params)
}
