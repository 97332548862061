/**
 * Menu
 */
import classNames from 'classnames'
import { useState } from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { useRouter } from 'next/router'

import Badge from 'components/badge'
import Layout from 'components/layout'
import { Icon, IconText } from 'components/media'

import { useMenu } from 'contexts/MenuContext'

import { isArray, isNumber, getClassWidths } from 'libs/utils'

export function Navbar( props ) {

	const {
		id,
		sticky,
		fixed,
		absolute,
		background,
		color,
		vAlign,
		zIndex,
		className,
		layoutClassName = "u-height",
		children
	} = props

	return (
		<div
			id={ id }
			className={ classNames(
				'c-navbar',
				{
					[`u-position-sticky u-${ sticky }`]: sticky,
					[`u-position-fixed u-${ fixed }`]: fixed,
					[`u-position-absolute u-${ absolute }`]: absolute,
					[`u-background-color--${ background }`]: background,
					[`u-color--${ color }`]: color
				},
				className
			) }
			style={ {
				zIndex: zIndex
			} }
		>
			<Layout
				isFlex
				isAuto
				size="tiny"
				vAlign={ vAlign }
				className={layoutClassName}
			>
				{ children }
			</Layout>
		</div>
	)
}

Navbar.propTypes = {
	sticky: PropTypes.string,
	fixed: PropTypes.string,
	absolute: PropTypes.string,
	background: PropTypes.string,
	color: PropTypes.string,
	zIndex: PropTypes.number,
	className: PropTypes.string,
	children: PropTypes.node
}

export function Menu( props ) {

	const {
		hasMobileSupport,
		hasLayout,
		isPrimary,
		items,
		align,
		valign,
		widths,
		color,
		type,
		className,
		children
	} = props

	// For mobile support
	const {
		isOpen,
		setIsOpen
	} = useMenu()
	const handleOpen = () => setIsOpen( ! isOpen )

	const hasChildren = () => (
		isArray( items ) &&
		items.filter( item => isArray( item.subItems ) && item.subItems.length ).length
	)

	const classes = classNames(
		'c-navbar__menu',
		{
			'o-layout__item u-flex': hasLayout,
			'o-layout__item--primary': hasLayout && isPrimary,
			'c-navbar__menu--mobile': hasMobileSupport,
			'c-navbar__menu--mobile-open': hasMobileSupport && isOpen,
			[`u-flex-justify--${ align }`]: align,
			[`u-flex-align--${ valign }`]: valign,
			[`u-color--${ color }`]: color,
			[`c-navbar__menu--${ type }`]: type,
			'c-navbar__menu--with-children': hasChildren()
		},
		getClassWidths( widths ),
		className
	)

	if( ! items )
		return <nav className={ classes }>{ children }</nav>

	return (
		<nav className={ classes }>
			<ul
				className={ classNames(
					'c-navbar__menu-items',
					{
						"o-layout o-layout--flex o-layout--auto o-layout--row o-layout--tiny": hasLayout
					}
				) }
			>
				{ items.map( ( item, index ) => (
					<MenuItem
						key={ index }
						{ ...item }
						hasLayout={ hasLayout }
					/>
				) ) }
			</ul>
			{ children }
			{ hasMobileSupport && (
				<Item
					className="c-navbar__item--menu-toggle"
					onClick={ handleOpen }
				>
					<Icon
						name={ isOpen ? 'close' : 'hamburger' }
						color="black"
					/>
					<IconText>
						Menu
					</IconText>
				</Item>
			) }
		</nav>
	)
}

Menu.defaultProps = {
	widths: '1/1'
}

Menu.propTypes = {
	hasLayout: PropTypes.bool,
	isPrimary: PropTypes.bool,
	items: PropTypes.arrayOf(
		PropTypes.shape( {
			icon: PropTypes.string,
			title: PropTypes.string,
			href: PropTypes.string,
			as: PropTypes.string,
			children: PropTypes.array
		} )
	),
	align: PropTypes.string,
	valign: PropTypes.string,
	widths: PropTypes.oneOfType( [
		PropTypes.string,
		PropTypes.object
	] ),
	color: PropTypes.string,
	type: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.node
}

function MenuItem( props ) {

	const {
		href,
		as,
		icon,
		title,
		badge,
		onClick,
		hasLayout,
		depth,
		subItems,
		isSelected
	} = props

	const router = useRouter()

	const selected = () => {
		if( router.asPath === ( as || href ) ) {
			return true
		}

		return isSelected === true
	}

	return (
		<li
			className={ classNames(
				'c-navbar__menu-item',
				{
					"o-layout__item u-flex": hasLayout
				}
			) }
		>
			<Item
				href={ href }
				as={ as }
				onClick={ onClick }
				badge={ badge }
				isSelected={ selected() }
			>
				{ icon
					? (
						<>
							<Icon
								name={ icon }
								color="black"
							/>
							<IconText>
								{ title }
							</IconText>
						</>
					) 
					: title
				}
			</Item>

			{ subItems.length > 0 && (
				<ul
					className={ classNames(
						'c-navbar__menu-items',
						'c-navbar__submenu-items'
					) }
				>
					{ subItems.map( ( item, index ) => (
						<MenuItem
							key={ index }
							{ ...item }
							hasLayout={ hasLayout }
							depth={ depth + 1 }
						/>
					) ) }
				</ul>
			) }
		</li>
	)
}

MenuItem.propTypes = {
	href: PropTypes.string,
	as: PropTypes.string,
	icon: PropTypes.string,
	title: PropTypes.string,
	onClick: PropTypes.func,
	hasLayout: PropTypes.bool,
	subItems: PropTypes.arrayOf(
		PropTypes.shape( {
			icon: PropTypes.string,
			title: PropTypes.string,
			href: PropTypes.string,
			as: PropTypes.string,
			children: PropTypes.array
		} )
	),
	depth: PropTypes.number
}

MenuItem.defaultProps = {
	subItems: [],
	depth: 0
}

function Item( props ) {

	const {
		href,
		as,
		onClick,
		badge,
		children,
		isSelected,
		className
	} = props;

	const classes = classNames(
		'c-navbar__item',
		{
			'c-navbar__item-selected': isSelected
		},
		className
	)

	if( href ) {
		return (
			<Link
				href={ href }
				as={ as }
			>
				<a
					className={ classes }
				>
					<span>{ children }</span>
					{ badge && <Badge { ...badge } isRight/> }
				</a>
			</Link>
		)
	}

	return (
		<button
			className={ classes }
			onClick={ onClick }
		>
			<span>{ children }</span>
			{ badge && <Badge { ...badge } isRight/> }
		</button>
	)
}

Item.propTypes = {
	href: PropTypes.string,
	as: PropTypes.string,
	onClick: PropTypes.func,
	children: PropTypes.node,
	isSelected: PropTypes.bool
}

export function NavDot( props ) {

	const {
		active,
		errors,
		onClick
	} = props

	let {
		steps
	} = props

	if( ! isArray( steps ) ) {
		if( ! isNumber( steps ) )
			return null

		steps = Array.from({length: steps}, (v, i) => i)
	}

	return (
		<nav
			className="o-nav-dot"
			aria-label="Navigation par étapes"
		>
			<ul
				className="o-nav-dot__container"
			>
				{ steps.map( (item, index) => (
					<li
						key={ index }
						className={ classNames(
							'o-nav-dot__item',
							{
								'o-nav-dot__item--active': active === index,
								'o-nav-dot__item--error': errors && errors.indexOf( item ) > -1
							}
						) }
						aria-label={ `Étape ${index+1}` }
						aria-current={ active === index ? 'true' : null }
					>
						<a
							className="o-nav-dot__link"
							onClick={ () => onClick( index ) }
						>
							{ index + 1 }
						</a>
					</li>
				) ) }
			</ul>
		</nav>
	)
}

NavDot.proptypes = {
	active: PropTypes.number,
	steps: PropTypes.oneOfType( [
		PropTypes.number,
		PropTypes.array
	] ),
	errors: PropTypes.array,
	onClick: PropTypes.func
}

NavDot.defaultProps = {
	onClick: () => null
}
