export default function UserClient(store, ApiClient) {

	var signup = function(params) {
		return ApiClient.post("users", { params, offline: true })
			.then(data => {
				store.dispatch({
					type: "SIGNUP",
					user: data
				})
				return data
			})
	}

	function me() {
		if (!ApiClient.checkForToken()) {
			return Promise.resolve({ error: "Unable to find some token" })
		}

		return ApiClient.get("users/me", {})
			.then(data => {
				if (data.error) {
					if (data.status == 404) {
						return ApiClient.logout()
					}

					return ApiClient.post("oauth/token", {
						params: { grant_type: "refresh_token" },
						offline: false,
						defaultParams: true
					})
						.then(data => {
							if (data.error || data.status == 401) {
								return ApiClient.logout()
							}

							return ApiClient.storeToken()
								.then(me)
						})
				}

				store.dispatch({
					type: "ME",
					user: data
				})
			})
	}

	var resetMe = function(callback) {
		store.dispatch({
			type: "RESET_ME"
		})
	}

	var forgotPassword = function(params) {
		return ApiClient.get("users/forgot-password", { params, offline: true })
	}

	var checkStamp = function(params, callback) {
		ApiClient.get("users/check-stamp", params, function(data) {
			store.dispatch({
				type: "STAMP",
				stamp: data
			})
			if (callback) callback(data)
		}, true)
	}

	var updatePassword = function(id, params) {
		return ApiClient
			.put("users/update-password", id, {params: params})
			.then(data => {
				store.dispatch({
					type: "UPDATE_PASSWORD",
					updated: true
				})
				return data
			})
	}

	return {
		signup: signup,
		me: me,
		resetMe: resetMe,
		forgotPassword: forgotPassword,
		checkStamp: checkStamp,
		updatePassword: updatePassword
	}

}
