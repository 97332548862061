import Router from 'next/router'

import Button, { Buttons } from 'components/button'
import Title from 'components/title'
import Markdown, { ContentMarkdown } from 'components/markdown'
import { Stores } from 'components/generic'
import { Grid } from 'components/layout'
import { GiftCard } from 'components/shop'
import { LoaderIcon } from 'components/loader'
import Card from 'components/card'
import Map from 'components/map'
import { StayCard } from 'components/stay-card'

import retailerClient from 'clients/retailer-client'

import { ProvideMap } from 'contexts/MapContext'

import { getTaxonChildren } from 'libs/taxon-getters'
import { scrollTo } from 'libs/navigation'
import { formatCategories } from 'libs/formatters'
import { PASS_TAXON, TICKET_TAXON } from 'libs/variables'
import { isArray } from 'libs/utils'

export const taxonInitialProps = {

	/**
	 * Cadeaux
	 */
	cadeaux: {
		retailers: async () => {
			const response = await retailerClient.getAll()

			if (isArray(response)) {
				return response.sort((a, b) => a.address.postcode - b.address.postcode)
			}

			return null
		}
	}
}

export const taxonHeader = {

	/**
	 * Cadeaux
	 */
	'cadeaux': {
		aside: (
			<Buttons>
				{/*<Button isText color="white">Découvrir</Button>*/}
				<Button
					id="hero-category-cadeaux-shops"
					onClick={ e => {
						e.stopPropagation()
						scrollTo( '#boutiques' )
					} }
				>
					Acheter en boutique
				</Button>
			</Buttons>
		),
		content: {
			id: 'offrir',
			card: GiftCard
		}
	},


	/**
	 * Sibylline Pass
	 */
	[PASS_TAXON]: {
		aside: (
			<Buttons>
				{/*<Button isText color="white">Découvrir</Button>*/}
				<Button
					id="hero-category-sibylline-pass-use"
					onClick={ e => {
						e.stopPropagation()
						scrollTo( '#utiliser' )
					} }
				>
					Utiliser votre Sibylline Pass
				</Button>
			</Buttons>
		),
		content: {
			id: 'offrir'
		}
	},


	/**
	 * Sibylline Ticket
	 */
	[TICKET_TAXON]: {
		aside: (
			<Buttons>
				{/*<Button isText color="white">Découvrir</Button>*/}
				<Button
					id="hero-category-sibylline-ticket-use"
					onClick={ e => {
						e.stopPropagation()
						scrollTo( '#utiliser' )
					} }
				>
					Utiliser votre Sibylline Ticket
				</Button>
			</Buttons>
		),
		content: {
			id: 'offrir'
		}
	},


	/**
	 * Séjours surprises
	 */
	"sejours-surprises": {
		content: {
			layout: "full-width",
			card: StayCard
		}
	}
}

export const taxonFooter = {

	/**
	 * Cadeaux
	 */
	'cadeaux': {
		id: 'ou-acheter',
		image: '',
		title: 'Où acheter nos cadeaux ?',
		description: '',
		more: "Acheter en boutique",
		aside: (
			<Buttons>
				{/*<Button
					isText
					color="white"
				>Les boutiques</Button>*/}
				<Button
					id="hero-category-cadeaux-discover"
					onClick={ e => {
						e.stopPropagation()
						scrollTo( '#offrir' )
					} }
				>
					Nos cadeaux
				</Button>
			</Buttons>
		),
		content: {
			id: "boutiques",
			header: (
				<>
					<Title size="large">Vous préférez acheter directement en boutique ?</Title>
					<Markdown>Retrouvez nos **Sibylline Pass** dans nos différents points de vente.</Markdown>
				</>
			),
			content: ({ retailers }) => (
				<ProvideMap>
					<Card 
						className="u-position-sticky u-top u-margin-bottom-none"
						style={ {
							zIndex: 1
						} }
					>
						<Map id="retailers-map" className="u-min-height--one-third u-min-height--half@desktop"/>
					</Card>
					<Stores
						items={ retailers }
					/>
				</ProvideMap>
			)
		}
	},

	/**
	 * Sibylline Pass
	 */
	[PASS_TAXON]: {
		id: 'utilisation',
		image: '',
		title: <>{ 'Utiliser votre' }<br/>{ 'Sibylline Pass' }</>,
		description: '',
		more: "C'est parti",
		aside: (
			<Buttons>
				{/*<Button isText color="white">C'est parti</Button>*/}
				<Button
					id="hero-category-sibylline-pass-discover"
					onClick={ e => {
						e.stopPropagation()
						scrollTo( '#offrir' )
					} }
				>
					Offrir un Sibylline Pass
				</Button>
			</Buttons>
		),
		content: {
			id: "utiliser",
			content: ( props ) => {

				// const router = useRouter()
				//
				// console.log(router);
				//
				// const [modal, setModal] = useState( false )
				const handleOpen = () => {
					// setModal( true )
					Router.push( '/formulaire/sibylline-pass' )
				}
				// const handleClose = () => {
				// 	setModal( false )
				// 	router.back()
				// }

				return (
					<>
						<ContentMarkdown
							isPost
							className="u-margin-bottom"
						>
							{ `## Vous avez reçu un SibyllinePass ?

**Il est l'heure de partir à la découverte de nos régions, à 2h30 maximum de votre domicile !**

Pour utiliser votre SibyllinePass, rien de plus simple :

- il vous suffit de remplir un questionnaire, pour nous indiquer les dates choisies ainsi que toutes vos préférences. Ce questionnaire nous permettra de personnaliser au maximum votre escapade.
- vous recevrez une notification pour la bonne prise en charge de votre escapade.
- Quelques jours avant votre départ, vous recevrez à votre domicile un carnet de bord, avec les dernières indications de ce qu'il faut mettre dans sa valise et surtout tous les indices qui sont numérotés avec la date et l'heure à laquelle il faut les ouvrir.
- Il ne reste plus qu'à vous laisser guider !` }
						</ContentMarkdown>
						<Button
							id="sibylline-pass-open-logbook"
							isFullWidth
							isPrimary
							// isLoading={ modal }
							onClick={ handleOpen }
							className="u-margin-bottom"
						>
							Utiliser votre Sibylline Pass
						</Button>
						{/*<Modal
							active={ modal }
							hasOverlay
							onClose={ handleClose }
							href="/formulaire/sibylline-pass"
						>
							<FormSibyllinePass/>
						</Modal>*/}
					</>
				)
			}
		}
	},

	/**
	 * Sibylline Ticket
	 */
	[TICKET_TAXON]: {
		id: 'utilisation',
		image: '',
		title: <>{ 'Utiliser votre' }<br/>{ 'Sibylline Ticket' }</>,
		description: '',
		more: "C'est parti",
		aside: (
			<Buttons>
				{/*<Button
					isText
					color="white"
				>C'est parti</Button>*/}
				<Button
					onClick={ e => {
						e.stopPropagation()
						scrollTo( '#offrir' )
					} }
				>Offrir un Sibylline Ticket</Button>
			</Buttons>
		),
		content: {
			id: "utiliser",
			header: (
				<>
					<Title size="large">Vous avez reçu un Sibylline Ticket ?</Title>
					<Markdown>
						{ `**Sélectionnez le type de pass ci-contre puis choisissez le créneau que vous souhaitez**

Ensuite pour réserver, rien de plus simple, il vous suffit de mettre votre numéro de billet dans la barre code de réduction lorsque vous avez choisi votre produit. Vous recevrez alors vos e-billets !` }
					</Markdown>
				</>
			),
			content: ( props ) => {

				const {
					taxons,
					error
				} = getTaxonChildren( 'evenements' )

				if( error ) {
					return (
						<Button size="small" isDisabled isFullWidth>
							Les événements n'ont pas pu être chargés.
						</Button>
					)
				}

				if( ! taxons ) {
					return <LoaderIcon isPrimary/>
				}

				return <Grid items={ formatCategories( taxons, { button: 'Utiliser votre Sibylline Ticket' } ) }/>
			}
		}
	}
}
