
import React, { useEffect, useState } from 'react'

import TaxonClient from 'clients/taxon-client'

export function getTaxonChildren( code ) {

	const [taxons, setTaxons] = useState( null )
	const [error, setError] = useState( null )

	const fetch = async () => {
		try {
			const response = await TaxonClient.getByCode( code )

			if( ! ('code' in response) || response.code < 400 ) {
				setTaxons( response.self.children )
			} else {
				setError( response.code )
			}

		} catch (e) {
			setError( 500 )
		}
	}

	useEffect(
	 	() => {
			fetch()
		},
		[]
	)

	return {
		taxons,
		error
	}
}
