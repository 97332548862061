
import Router, { useRouter } from 'next/router'
import * as moment from 'moment-timezone'

import { TEXT_SEPARATOR, SITE_URL, CSS_BREAKPOINT_SEPARATOR, STAY_TAXON, PASS_TAXON, TICKET_TAXON } from 'libs/variables'
import { PICKUP_ADDRESS } from 'libs/variables'
import { taxonInitialProps } from 'libs/taxon-config'

import { useCart } from 'contexts/CartContext'
import { formatCategory } from 'libs/formatters'
import FileResizer from 'react-image-file-resizer'

/**
 * Use inuitcss widths
 *
 * @param {mixed} widths object of ratio widths with responsive breakpoint at keys or single ratio width string
 * @param {string} prefix push or pull
 *
 * @returns {mixed} array or null
 */
export function getClassWidths( widths, prefix ) {
	if( isObject( widths ) ) {
		return Object.entries(widths).map( width => {
			if( isNull( width[0] ) || width[0] === 'null' ) {
				return prefix ? `u-${prefix}-${width[1]}` : `u-${width[1]}`
			} else {
				return prefix ? `u-${prefix}-${width[1]}${CSS_BREAKPOINT_SEPARATOR}${width[0]}` : `u-${width[1]}${CSS_BREAKPOINT_SEPARATOR}${width[0]}`
			}
		} )

	} else if( isString( widths ) ) {
		return prefix ? `u-${prefix}-${widths}` : `u-${widths}`
	}

	return null
}

/**
 * Check if current method is selected
 *
 * @param {array} methods shipping or billing methods
 * @param {object} method shipping or billing method
 *
 * @returns {boolean}
 */
export function isSelectedMethod( methods, method ) {
	if( isEmpty( methods ) || isEmpty( methods[0].method ) )
		return false

	return method.code === methods[0].method.code
}

/**
 * Format date
 *
 * @param {string} dataTime
 *
 * @returns {string} formatted date
 */
export function parseDate( dateTime ) {
	moment.locale( 'fr' )
	return moment.tz(dateTime, "Europe/Paris").format( 'DD MMM YYYY' )
}

/**
 * Format readable date
 *
 * @param {string} dataTime
 *
 * @returns {string} formatted date
 */
export function parseReadableDate( dateTime ) {
	moment.locale( 'fr' )
	return moment.tz(dateTime, "Europe/Paris").format("LL")
}

/**
 * Format date
 *
 * @param {string} dataTime
 * @param {string} sep
 *
 * @returns {string} formatted dateTime
 */
export function parseDateTime( dateTime, sep ) {
	sep = sep || TEXT_SEPARATOR

	moment.locale( 'fr' )
	return moment.tz(dateTime, "Europe/Paris").format(`ll[${sep}]HH[h]mm`)
}

/**
 * Format price
 *
 * @param {number} amount
 *
 * @returns {string} formatted price with two decimals
 */
export function parsePrice( amount ) {
	if( ! isNumber( amount ) ) {
		amount = Number( amount )
	}

	// Get price from cents
	let priceAmount = amount / 100

	// Display decimal if needed
	if( ! Number.isInteger( priceAmount ) ) {
		priceAmount = priceAmount.toFixed(2)
	}

	// Convert number to string
	priceAmount = priceAmount.toString().replace('.', ',')

	return priceAmount
}

/**
 * Get last order payment
 *
 * @param {object} order
 */
export function getOrderPayment( order ) {

	if( ! isEmpty( order.payments ) ) {
		const last = order.payments[order.payments.length - 1]

		if( isObject( last ) && 'state' in last ) {
			return last
		}
	}

	return null
}

/**
 * Get last order shipment
 *
 * @param {object} order
 */
export function getOrderShipment( order ) {

	if( ! isEmpty( order.shipments ) ) {
		const last = order.shipments[order.shipments.length - 1]

		if( isObject( last ) && 'state' in last ) {
			return last
		}
	}

	return null
}

/**
 * Get order state
 */
export function getOrderState( order ) {

	if( isObject( order ) && 'state' in order ) {

		const payment = getOrderPayment( order )
		const shipment = getOrderShipment( order )

		if( payment.state === 'completed' && shipment ) {

			switch( shipment.state ) {
				case 'shipped':
					return 'Terminée'

				case 'cancelled':
					return 'Annulée'

				case 'returned':
					return 'Retournée'

				case 'cart':
					return ''

				default:
					return 'En préparation'
			}
		}

		if( payment ) {

			switch( payment.state ) {
				case 'completed':
					return 'Terminée'

				case 'failed':
					return 'Échouée'

				case 'cancelled':
					return 'Annulée'

				case 'refunded':
					return 'Remboursée'

				case 'cart':
					return ''

				default:
					return 'En attente de paiement'
			}
		}

		switch( order.state ) {
			case 'new':
				return 'En cours'

			case 'cancelled':
				return 'Annulée'

			case 'fulfilled':
				return 'Terminée'
		}
	}

	return ''
}

/**
 * Get payment state
 */
export function getPaymentState( payment ) {

	if( isObject( payment ) && 'state' in payment ) {

		switch( payment.state ) {
			case 'completed':
			case 'paid':
				return 'Payé'

			case 'failed':
				return 'Échoué'

			case 'cancelled':
				return 'Annulé'

			case 'refunded':
				return 'Remboursé'

			case 'partially_paid':
				return 'En cours'

			case 'cart':
				return ''

			default:
				return 'En attente'
		}
	}

	return ''
}

/**
 * Get shipment state
 */
export function getShipmentState( shipment ) {

	if( isObject( shipment ) && 'state' in shipment ) {

		switch( shipment.state ) {
			case 'shipped':
				return 'Expédiée'

			case 'cancelled':
				return 'Annulée'

			case 'returned':
				return 'Retournée'

			case 'cart':
				return ''

			default:
				return 'En préparation'
		}
	}

	return ''
}

/**
 * Format cart/order item description
 *
 * @param {object} item cart item
 * @param {string} sep
 */
export function getCartItemDescription( item, sep ) {
	if( isEmpty( item.product ) && isEmpty( item.product.variants ) ) {
		return ''
	}

	sep =  sep || TEXT_SEPARATOR

	const firstVariant = Object.values(item.product.variants)[0]

	switch (item.product.taxons.main.code) {
		case TICKET_TAXON:
			return 'name' in firstVariant ? firstVariant.name : ''

		case PASS_TAXON:
		case STAY_TAXON:
			return getVariantDescription( firstVariant, item.product.taxons.main.code, sep )

		default:
			moment.locale('fr');
			let shortDescriptionPart = []

			let start = item.product.attributes.time_slot_date_start || null
			if (start) {
				shortDescriptionPart.push( parseDateTime( start, sep ) )
			}

			if( 'name' in firstVariant ) {
				shortDescriptionPart.push( firstVariant.name )
			}

			return shortDescriptionPart.join( sep )
	}
}

/**
 * Get product options
 */
export function getProductSecondaryDescription( product, sep ) {
	if( isEmpty( product ) ) {
		return ''
	}

	sep =  sep || TEXT_SEPARATOR

	const firstVariant = Object.values(product.variants)[0]

	switch (product.taxons.main.code) {
		case TICKET_TAXON:
			return isObject( firstVariant ) ? firstVariant.name : ''

		case PASS_TAXON:
		case STAY_TAXON:
			return product.shortDescription ? product.shortDescription : getVariantDescription( firstVariant, product.taxons.main.code, sep )

		default:
			let shortDescription = ''

			let start = product.attributes.time_slot_date_start || null
			if (start) {
				shortDescription = parseDateTime( start, sep )
			}

			return shortDescription
	}
}

export function getVariantDescription( variant, taxonCode, sep ) {

	// get description
	if( ! isEmpty( variant.description ) && taxonCode !== STAY_TAXON ) {
		return variant.description

	// Compose description from variant args
	} else if( ! isEmpty( variant.nameAxis ) ) {

		let shortDescriptionPart = []

		sep = sep || ' • '
		// TODO: put this elsewhere and globalize it!
		let translates = {
			default: {
				'number_of_nights': ['nuit', 'nuits'],
				'number_of_meals': ['repas', 'repas'],
				'number_of_activities': ['activité', 'activités'],
				'number_of_participants': ['participant', 'participants'],
			},
			// TODO: Deprecated???
			evenements: ['time_slot_date_start', 'time_slot_date_end']
		}
		Object.entries( variant.nameAxis ).filter(option => option[0] !== 'stay_id').forEach( option => {
			const translate = translates[taxonCode] ?? translates.default
			if( translate && option[1] > 0 ) {
				shortDescriptionPart.push( option[1] + '\xa0' + (option[1] > 1 ? translate[option[0]][1] : translate[option[0]][0]) )
			}
		} )

		return shortDescriptionPart.join( sep )
	}

	return ''
}

/**
 * Defines the product variants sort order
 */
export function productVariantsCompare( a, b ) {
	if( isObject( a ) && isObject( b ) ) {
		return a.price.current - b.price.current
	}
	return 0
}

export function productHasMultiplePrices( variants ) {
	const prices = []

	variants.forEach( variant => {
		if( prices.indexOf( variant.price.current ) < 0 ) {
			prices.push( variant.price.current )
		}
	} )

	return prices.length > 1
}

/**
 * Return all product variants tax names
 *
 * @param {object} product
 */
export function getProductTaxNames( product ) {
	return [ ...new Set( Object.values(product.variants).map( variant => 'taxCategory' in variant ? variant.taxCategory.name : null ) ) ].filter( item => item )
}

/**
 * Filter variants
 * TODO: do this in sylius
 *
 * @param {object} variants product variants
 * @returns {booleen}
 */
export function getAvailableVariants( variants, filter ) {

	if( isObject( filter ) && 'name' in filter ) {
		return Object.values( variants ).filter( variant => filter.name in variant.nameAxis && variant.nameAxis[filter.name] === filter.value || ! (filter.name in variant.nameAxis) )
	}

	return variants
}

/**
 * Check if product is available
 *
 * @param {object} product product
 */
export function hasProductStock( product ) {
	return getProductStock( product ) > 0
}

/**
 * Get product stock quantity
 *
 * @param {object} product product
 */
export function getProductStock( product ) {
	return Object.values( product.variants ).reduce( (accumulator, variant) => accumulator + getVariantStock( variant ), 0 )
}

/**
 * Check if variant is available
 *
 * @param {object} variant variant
 */
export function hasVariantStock( variant ) {
	return getVariantStock( variant ) > 0
}

/**
 * Get variant stock quantity
 *
 * @param {object} variant variant
 */
export function getVariantStock( variant ) {
	if( ! variant.isTracked )
		return 99
	return variant.onHand >= variant.onHold ? variant.onHand - variant.onHold : 0
}

/**
 * Check if product is simple
 *
 * @param {object} product
 * @returns {booleen}
 */
export function isSimpleProduct( product ) {
	const variants = Object.values( product.variants )
	
	return variants.length === 1 && Object.values( variants[0].nameAxis ).length === 0
}

/**
 * Get product quantity params from attributes
 *
 * @param {object} product
 * @returns {object}
 */
export function getProductQuantityParams( product ) {
	if ( ! product.attributes.quantity_default && ! product.attributes.quantity_min && ! product.attributes.quantity_max )
		return null

	const params = {
		default: product.attributes.quantity_default || 1,
		min: product.attributes.quantity_min || 1,
		max: product.attributes.quantity_max || 99
	}

	if ( params.min > params.max ) {
		params.min = product.attributes.quantity_max || 1
		params.max = product.attributes.quantity_min || 99
	}

	if ( params.default < params.min || params.default > params.max )
		params.default = params.min

	return params
}

/**
 * Check if product is an event
 *
 * @param {object} product
 * @returns {booleen}
 */
export function isEvent( product, future = false ) {

	if( future ) {
		console.log( moment.now().diff( moment( product.attributes.time_slot_date_start ) ) )
	}

	return inTaxon( product, 'evenements' )
}

/**
 * Check if is a past event
 *
 * @param {object} product
 * @returns {booleen}
 */
export function isPastEvent( product ) {

	if( isUndefined( product.attributes.time_slot_date_start ) )
		return false

	return moment( product.attributes.time_slot_date_start ).diff( moment.now() ) < 0
}

/**
 * Check if product is a gift
 *
 * @param {object} product
 */
export function isGift( product ) {
	return inTaxon( product, 'cadeaux' )
}

/**
 * Check cart or order has pickup shipments method
 *
 * @param {object} cartOrOrder
 */
export function isPickUpShipment( cartOrOrder ) {
	if( ! isNull( cartOrOrder ) && ! isEmpty( cartOrOrder.shipments ) ) {
		return cartOrOrder.shipments[0].method.code === process.env.PICKUP_METHOD_CODE
	}

	return false
}

/**
 * Check cart or order has gifts
 *
 * @param {object} cartOrOrder
 */
export function hasGifts( cartOrOrder ) {
	if( ! isNull( cartOrOrder ) && ! isEmpty( cartOrOrder.items ) ) {
		return cartOrOrder.items.filter( item => isDeliverableGift( item.product ) ).length > 0
	}

	return false
}

/**
 * Check if has different gift Addresses
 *
 * @param {object} giftAddresses
 */
export function hasDifferentGiftAdresses( giftAddresses ) {
	return isObject( giftAddresses ) && ! Object.values( giftAddresses ).every( item => item.every( gift => ! gift.differentAddress ) )
}

/**
 * Check if product is a gift and it's deliverable
 *
 * @param {object} product
 */
export function isDeliverableGift( product ) {
	if( ! isObject( product.variants[0] ) )
		return false

	return isGift( product ) && ( ! hasOptionFormat( product.variants[0] ) || isFormatTicket( product.variants[0] ) )
}

/**
 * Get a taxon by code from all taxons
 *
 * @param {array} taxons
 * @param {string} taxonCode
 */
export function getTaxonObjectFromAll( taxons, taxonCode ) {
	let taxonObject = { code: taxonCode }

	taxons.some( taxon => {
		if ( taxon.code === taxonCode ) {
			taxonObject = taxon
			return true

		} else if ( taxon.children.length > 0 ) {
			const theTaxon = taxon.children.find( child => child.code === taxonCode )

			if( theTaxon ) {
				taxonObject = formatCategory( theTaxon )
				return true
			}
		}
		return false
	} )

	return taxonObject
}

/**
 * Check if product is in taxon
 *
 * @param {object} product
 * @param {string} taxonCode
 */
export function inTaxon( product, taxonCode ) {
	return isObject( product.taxons.main ) && product.taxons.main.code === taxonCode || isArray( product.taxons.main.ancestors ) && product.taxons.main.ancestors.filter( taxon => taxon.code === taxonCode ).length > 0
}

/**
 * Check if taxon is is published
 *
 * @param {object} taxon
 * @return {boolean}
 */
export function isTaxonPublished( taxon ) {
	if ( ! isObject( taxon ) )
		return false

	if ( taxon.ancestors && isObject( taxon.ancestors[0] ) ) {
		return taxon.published && taxon.ancestors[0].published
	}

	return taxon.published
}

/**
 * Check if variant is not an e-ticket
 *
 * @param {object} variant
 */
export function isFormatTicket( variant ) {
	if( ! hasOptionFormat( variant ) )
		return false

	return variant.nameAxis.format === 'Enveloppe cadeau' // TODO: use code instead of name
}

/**
 * Check if variant has option "format"
 *
 * @param {object} variant
 */
export function hasOptionFormat( variant ) {
	if( ! isObject( variant ) || ! ('nameAxis' in variant) )
		return false

	return 'format' in variant.nameAxis
}

/**
 * Retreive audience code from andience name... Voila!
 *
 * TODO: Needs to get this from api
 *
 * @param {string} name
 */
export function getAudienceCode( name ) {
	switch( name ) {
		case 'Pack Famille 2':
			return 'familyPack2'
		case 'Pack Famille 1':
			return 'familyPack1'
		case 'Pack Tribu':
			return 'tribePack'
		case 'Pack Copains':
			return 'friendPack'
		case 'Couple':
			return 'couple'
		case 'Enfant':
			return 'child'
		case 'Ado':
			return 'teen'
		case 'Adulte':
			return 'adult'
		case 'Unique':
			return 'unique'
	}
}

/**
 * Convert form field name to id
 *
 * @param {string} name
 */
export function nameToId( name ) {
	if( ! isString( name ) )
		return ''

	return name.replace(/\]$/g, '').replace(/[\]|\[|\.]/g, '-')
}

/** 
 * Sequence generator function (commonly referred to as "range", e.g. Clojure, PHP etc)
 * 
 * @param {number} start
 * @param {number} stop
 * @param {number} step
 * @returns {array}
 */ 
export const range = (start, stop, step = 1) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step))

/**
 *
 * @param {*} func
 */
export function isEmpty( value ) {
	if( isObject( value ) ) {
		return Object.entries( value ).length === 0
	}

	if( isArray( value ) ) {
		return value.length === 0
	}

	if( isString( value ) ) {
		return value === ''
	}

	return true
}

/**
 *
 * @param {*} func
 */
export function isUndefined( value ) {
	return value === undefined
}

/**
 *
 * @param {*} func
 */
export function isNull( value ) {
	return value === null
}

/**
 *
 * @param {*} func
 */
export function isString( string ) {
	return typeof string === 'string'
}

/**
 *
 * @param {*} func
 */
export function isNumber( number ) {
	return typeof number === 'number'
}

/**
 *
 * @param {*} func
 */
export function isArray( array ) {
	return array instanceof Array
}

/**
 *
 * @param {*} func
 */
export function isObject( object ) {
	return object instanceof Object && ! isArray( object )
}

/**
 *
 * @param {*} func
 */
export function isFunction( func ) {
	return typeof func === 'function'
}

/**
 * Randomize array
 *
 * @param {array} array
 */
export function arrayShuffle( array ) {
	if ( ! isArray( array ) )
		return []

	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}

	return array
}

/**
 * Check if var is a react component
 *
 * DEPRECIATED
 */
export function isReactComponent( element ) {
	return isObject( element ) && ('$$typeof' in element)
}

/**
 * Check if array contains this react component
 *
 * DEPRECIATED
 */
export function containsComponent( children, componantName ) {

	if( ! isArray( children ) ) {
		children = [children]
	}

	return children.map( item => ( isReactComponent( item ) && item.type.name === componantName ) ).indexOf( true ) > -1
}

/**
 *
 */
export function isServerSide() {
	return typeof window === 'undefined'
	// inspiration cinetech
	// const isBrowser = typeof window !== 'undefined'
}

/**
 * Check url
 *
 * @param {string} url
 */
export function isExternal( url ) {
	if( ! isServerSide() ) {
		const urlObject = parseUrl( url )
		return window.location.host !== urlObject.host
	}

	return false
}

/**
 * Check for Preview mode
 *
 * Pass 'preview' param to url to enabled preview mode
 */
export const isPreview = () => {
	const router = useRouter()

	return ! isUndefined( router.query.preview )
}

/**
 * Format canonical url
 *
 * @param {string} url
 */
export function canonicalUrl( url ) {
	let canonical = SITE_URL

	if( url ) {

		if( url[0] === '/' ) {
			canonical += url

		} else {
			canonical = url
		}
	}

	// Remove hash & search
	canonical.replace(/#.+/, '').replace(/\?.+/, '')

	// canonical must always have trailing slash
	if( canonical.substring(canonical.length-1) !== '/' ) {
		canonical += '/'
	}

	return canonical
}

/**
 * Parse url to get an object similar to window.location
 *
 * @param {string} url
 *
 * @returns {object} urlObject
 */
export function parseUrl( url ) {
	if( ! isString( url ) ) {
		url = url.toString()
	}

	if( url[0] === '/' ) {
		url = window.location.origin + url
	}

	const matches = url.match( /^((https?:)\/\/(([^:|\/]+):?([0-9]+)?))(\/?[^\#]+)?\#?(.+)?$/ )

	const urlObject = {
		hash: '',
		host: '',
		hostname: '',
		href: '',
		origin: '',
		pathname: '',
		port: '',
		protocol: '',
		search: ''
	}

	if( ! isEmpty( matches ) ) {
		urlObject.href = matches[0] || ''
		urlObject.origin = matches[1] || ''
		urlObject.protocol = matches[2] || ''
		urlObject.host = matches[3] || ''
		urlObject.hostname = matches[4] || ''
		urlObject.port = matches[5] || ''
		urlObject.pathname = matches[6] || ''
		urlObject.search = matches[7] || ''
		urlObject.hash = matches[7] || ''
	}

	return urlObject
}

/**
 * Has error in response
 *
 * @param {boolean} response
 */
export function hasError( response ) {
	return (
		isObject( response ) && (
			'code' in response && response.code >= 400 ||
			'error' in response && response.error
		) ||
		response === "You need to be logged in with the same user that wants to complete the order" // WTFF!!!
	)
}

/**
 * Return promise response callback
 *
 * @param {promise} response
 * @param {function} callback
 * @param {function} errorCallback
 *
 * @returns {}
 */
export function responseCallback( response, callback, errorCallback ) {
	if( response ) {
		if( hasError( response ) ) {
			if( isFunction( errorCallback ) ) {
				return errorCallback( response )
			}
			console.error( isObject( response ) ? `${( 'code' in response ? `[${response.code}] ` : '' )}${response.message}` : response )
		} else {
			if( isFunction( callback ) ) {
				return callback( response )
			}
		}
	}

	return response
}

/**
 * Return sylius error to humain readable error
 *
 * @param {string} error sylius code
 *
 * @returns {string}
 */
export function getErrorTranslation( error ) {
	switch( error ) {
		case 'sylius.shop_api.coupon.not_valid':
			return "Ce coupon n'est pas valide"
			break
		case 'This email is already used.':
			return 'Cet email est déjà utilisé.'
			break
		case 'You need to be logged in with the same user that wants to complete the order':
			return 'Vous devez être connecté pour finaliser votre commande.'
			break
		default:
			return error
			break
	}
}

/**
 * Return sylius error to humain readable error
 *
 * @param {string} error message
 * @param {string} sep errors separator - default: ', '
 *
 * @returns {string}
 */
export function parseError( error, sep ) {
	sep = sep || ', '

	let errorMessage = ''

	if( isObject( error ) ) {
		if( 'errors' in error ) {
			errorMessage = Object.values( error.errors ).map( values => values.map( er => getErrorTranslation( er ) ).join( sep ) ).join( sep )
		} else if( 'message' in error ) {
			errorMessage = getErrorTranslation( error.message )
		} else {
			errorMessage = 'Une erreur est survenue'
		}
	} else {
		errorMessage = error
	}

	return errorMessage
}

/**
 *
 * @param {object} a address
 * @param {object} b address
 *
 * @returns {booleen}
 */
export function compareAddresses( a, b ) {
	return isObject( a ) && isObject( b ) && JSON.stringify(a) === JSON.stringify(b)
}

/**
 * Check for current user
 *
 * DEPRECIATED
 */
export function isUserAuthenticated(me) {
	if(!me){
		Router.push('/compte/connexion')
	}
	else{
		console.log("user authenticated already")
	}
}

/**
 * Custom gift shipemnts
 *
 * @param {object} cartOrOrder
 * @param {string} taxonCode
 *
 * @returns {object} {code: '', name: '', amount: ''}
 */
export function getGiftShipment( cartOrOrder, taxonCode ) {

	if( isPickUpShipment( cartOrOrder ) ) {
		return cartOrOrder.shippingMethods.filter( method => method.code === process.env.PICKUP_METHOD_CODE )[0]
	}

	if( taxonCode ) {
		switch( taxonCode ) {
			default:
				return cartOrOrder.shippingMethods.filter( method => method.code === taxonCode )[0]
		}
	}

	return {}
}

/**
 * Get pick up address fields from cart channel
 *
 * @param {booleen} withDescription
 *
 * @returns {object} address object
 */
export function getPickUpAddress( withDescription ) {

	withDescription = withDescription || false

	const {
		cart
	} = useCart()

	if( isNull( cart ) || ! ('channel' in cart) )
		return PICKUP_ADDRESS

	if( withDescription ) {

		return Object.assign(
			{},
			cart.channel.address,
			{
				phoneNumber: cart.channel.description
			}
		)
	}

	return cart.channel.address
}

/**
 * Get unique ID
 *
 * @see https://gist.github.com/gordonbrander/2230317
 */
export function uniqueID() {
	// Math.random should be unique because of its seeding algorithm.
	// Convert it to base 36 (numbers + letters), and grab the first 9 characters
	// after the decimal.
	return Math.random().toString(36).substr(2, 9);
}

/**
 * Get first formik error name parts
 *
 * @param {object} object
 * @param {array} parts
 */
export function getFirstError( object, parts = [] ) {

	if( isObject( object ) ) {
		const part = Object.keys( object )[0]
		parts.push( part )

		return getFirstError( object[part], parts )
	} else {
		return parts
	}
}

/**
 * Resize image before upload it
 *
 * @param {File} file
 */
export const resizeFile = file => new Promise( resolve => {
	FileResizer.imageFileResizer( file, 1024, 1024, 'JPEG', 80, 0,
		uri => {
			resolve( uri )
		},
		'base64'
	)
} )

export const getTaxonInitialProps = taxonCode => {
	const taxonProps = {
		props: {},
		getters: {}
	}

	if (isUndefined(taxonInitialProps[taxonCode]))
		return taxonProps

	Object.entries(taxonInitialProps[taxonCode]).forEach(([prop, getter]) => {
		taxonProps.props[prop] = null
		taxonProps.getters[prop] = getter
	})

	return taxonProps
}
