import ApiClientFactory from './api-client'

import UserClient from './user'

function capitalizeFirstLetter(string) {
	string = string.charAt(0).toUpperCase() + string.slice(1)
	var index, str
	while((index = string.indexOf('_')) !== -1) {
		if (index < string.length - 1) {
			str = string[index] + string[index + 1]
			string = string.replace(str, string[index + 1].toUpperCase())
		} else {
			string = string.replace('_', '')
		}
	}
	return string
}

function createClient(name, plural, store, ApiClient, localConfig) {
	const fetchAll = function(params, { offline = false, append = false, prepend = false, dispatch = true, updatePagination = true }) {
		return ApiClient.get(plural, { params, offline })
			.then(function(data) {
				if (dispatch) {
					const toDispatch = {
						type: plural.toUpperCase()
					}
					toDispatch[plural] = data
					toDispatch["append"] = append
					toDispatch["prepend"] = prepend
					toDispatch["updatePagination"] = updatePagination
					store.dispatch(toDispatch)
				}

				return data
			})
	}

	const fetchOne = function(id, options = {}) {
		const { offline = false, dispatch = true } = options

		return ApiClient.get(plural + "/" + id, { offline })
			.then(function(data) {
				if (dispatch) {
					const toDispatch = {
						type: name.toUpperCase()
					}
					toDispatch[name] = data
					store.dispatch(toDispatch)
				}

				return data
			})
	}

	const create = function(params, options = {}) {
		const { offline = false } = options

		return ApiClient.post(plural, { params, offline })
			.then(function(data) {
				if (data.error) {
					throw data
				}

				const toDispatch = {
					type: "NEW_" + name.toUpperCase()
				}
				toDispatch[name] = data
				store.dispatch(toDispatch)

				return data
			})
	}

	const update = function(id, params, { offline = false, dispatch = true, log = true }) {
		return ApiClient
			.put(plural, id, {params, offline, defaultParams: false, log})
			.then(data => {
				if (dispatch) {
					const toDispatch = {
						type: "UPDATE_" + name.toUpperCase()
					}
					toDispatch[name] = data
					store.dispatch(toDispatch)
				}
				return data
		})
	}

	const destroy = function(id) {
		const { idField = 'id' } = localConfig || {}
		ApiClient.destroy(plural, id, function(data) {
			store.dispatch({
				type: "DESTROY_" + name.toUpperCase(),
				[idField]: data[idField]
			})
		})
	}

	const upload = function(id, fieldName, file) {
		ApiClient.upload(plural + '/' + id + '/' + fieldName, fieldName, file, function(data) {
			const toDispatch = {
				type: "UPDATE_" + name.toUpperCase()
			}
			toDispatch[name] = data
			store.dispatch(toDispatch)
		})
	}

	const deleteFile = function(id, fieldName) {
		ApiClient.destroy(plural, id + "/" + fieldName, function(data) {
			const toDispatch = {
				type: "UPDATE_" + name.toUpperCase()
			}
			toDispatch[name] = data
			store.dispatch(toDispatch)
		})
	}

	const removeFromState = function(id, callback) {
		store.dispatch({
			type: `DESTROY_${name.toUpperCase()}`,
			id
		})

		if (callback) {
			callback()
		}
	}

	const reset = function() {
		store.dispatch({ type: "RESET_" + plural.toUpperCase() })
	}

	const pushInState = function(data, { update = true, target = undefined, to_plural = false }) {
		let toDispatch = {
			type: (update ? "UPDATE_" : "NEW_") + name.toUpperCase()
		}
		toDispatch[to_plural ? plural : name] = data
		store.dispatch(toDispatch)
		if (target) {
			toDispatch = {type: target}
			toDispatch[to_plural ? plural : name] = data
			store.dispatch(toDispatch)
		}
	}

	const functions = {
		name,
		plural,

		fetchAll,
		fetchOne,
		create,
		update,
		destroy,
		upload,
		deleteFile,

		reset,
		pushInState
	}

	let funx
	if (localConfig && localConfig.client) {
		funx = localConfig.client(name, plural, store, ApiClient)
		for(let key in funx) {
			functions[key] = funx[key]
		}
	}

	if (name === "user") {
		funx = UserClient(store, ApiClient)
		for(let key in funx) {
			functions[key] = funx[key]
		}
	}

	return functions
}

export default function createClients(config, store) {

	const ApiClient = ApiClientFactory(config, store)

	const coreClients = {
		ApiClient: ApiClient
	}

	const clients = config.clients
	for (var index in clients) {

		var localConfig, clientName, plural
		if (clients[index] instanceof Object) {
			localConfig = clients[index]
			clientName = localConfig.name
			plural = localConfig.plural ? localConfig.plural : clientName + "s"
		} else {
			clientName = clients[index]
			plural = clientName + "s"
		}

		coreClients[capitalizeFirstLetter(clientName) + "Client"] = createClient(clientName, plural, store, ApiClient, localConfig)
	}

	if (!coreClients["UserClient"]) {
		coreClients["UserClient"] = createClient("user", "users", store, ApiClient)
	}
	if (!coreClients["PageClient"]) {
		coreClients["PageClient"] = createClient("page", "pages", store, ApiClient)
	}


	return coreClients
}

