import createClients from "./create-clients"

const config = {
	middlewares: false,
	fake_api: false
}
const store = {
	dispatch: (toDispatch) => {console.log(toDispatch)}
}
const params = {}
const offline = true

let ApiClient;

const getApiClient = () => {
	if (!ApiClient) ApiClient = createClients(config, store).ApiClient

	return ApiClient;
}

export default {

	getAll: () => getApiClient().get( "/list-retailer", {params, offline} ),

	getRetailers: () => getApiClient().get( "/list-retailer", {params: {agency: 0}, offline} ),

	getAgencies: () => getApiClient().get( "/list-retailer", {params: {agency: 1}, offline} ),

}
