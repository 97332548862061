
import classNames from 'classnames'
import { Icon } from './media'

export default function Loader( props ) {

	const {
		isPrimary
	} = props

	return (
		<span
			className={ classNames(
				'u-loader',
				{
					'u-loader--primary': isPrimary
				}
			) }
		>
			<span className="u-loader__container"></span>
		</span>
	)
}

export function LoaderIcon( props ) {
	return <Icon name="loader"/>
}

export function LoaderSection( props ) {

	const {
		message
	} = props

	return (
		<div className="u-loader__section u-min-height--full">
			<Loader isPrimary/>
			{ message && (
				<div className="u-loader__text">
					{ message }
				</div>
			) }
		</div>
	)
}
