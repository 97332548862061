
import { getProductSecondaryDescription, isArray, isNull, isUndefined } from 'libs/utils'

/**
 *
 * @param object
 * @param ancestors
 * @returns {string|*}
 */
function formatUri(object = {code: ''}, ancestors = []) {
		let path = [object.code];
		ancestors.forEach(ancestor => {
			path.push(ancestor.code)
		})
		return '/' + path.reverse().join('/')
}

function formatUriAs(object = {code: ''}, ancestors = []) {
	let uri = formatUri(object, ancestors).substr(1).split('/')
	let as = ['[taxon]', '[child]', '[product]']

	return '/' + as.slice(0,uri.length).join('/')
}

export function formatProductUri (object = {code: ''}) {
	let ancestors = []
	ancestors.push(object.taxons.main)
	if( object.taxons.main.ancestors ) {
		ancestors = ancestors.concat(object.taxons.main.ancestors)
	}
	return formatUri(object, ancestors)
}

export function formatProductUriAs (object = {code: ''}) {
	let ancestors = []
	ancestors.push(object.taxons.main)
	if( object.taxons.main.ancestors ) {
		ancestors = ancestors.concat(object.taxons.main.ancestors)
	}
	return formatUriAs(object, ancestors)
}

export function formatTaxonUri (object = {code: ''}) {
	return formatUri(object, object.ancestors)
}

export function formatTaxonUriAs (object = {code: ''}) {
	return formatUriAs(object, object.ancestors)
}

/**
 *
 * @param images
 * @returns {*}
 */
export function formatImage(images) {
	return images[0] ? images[0].cachedPath : null
}

/**
 *
 * @param {array} categories
 * @param {object} override
 * @returns {array}
 */
export function formatCategories( categories, override = {} ) {
	if( ! isArray( categories ) )
		return []

	return categories.filter(category => !!category.published).map( category => formatCategory( category, override ) )
}

/**
 *
 * @param category
 * @param override
 * @returns {{button: string, image: (*|null), description: *, shortDescription: *, href: string, title: *}}
 */
export function formatCategory (category, override = {}) {

	const metaDescription = category.shortDescription || category.description && category.description.substring( 0, 150 ) // TODO: strip all markdown

	return Object.assign(
		category,
		{
			image: formatImage(category.images),
			title: category.name,
			button: category.buttonDescription,
			href: formatTaxonUriAs(category),
			as: formatTaxonUri(category),
			metaDescription
		},
		override
	)
}

/**
 *
 * @param {array} products
 * @param {object} override
 * @returns {array}
 */
export function formatProducts( products, override = {} ) {
	if( ! isArray( products ) )
		return []

	return products.map( product => formatProduct( product, override ) )
}

/**
 *
 * @param product
 * @param override
 * @returns {{button: *, image: (*|null), price: number, description: *, shortDescription: *, href: string, title: *}}
 */
export function formatProduct(product, override = {}) {

	const secondaryDescription = getProductSecondaryDescription( product )
	const metaDescription = product.metaDescription || product.shortDescription || product.description && product.description.substring( 0, 150 ) // TODO: strip all markdown

	return Object.assign(
		product,
		{
			title: product.name,
			image: formatImage(product.images),
			button: product.buttonDescription || 'Découvrir',
			href: formatProductUriAs(product),
			as: formatProductUri(product),
			secondaryDescription,
			metaDescription
		},
		override
	)
}

/**
 *
 * @param {array} news
 * @returns {array}
 */
export function formatNews(news) {
	if(!isArray(news))
		return []

	return news.map(oneNews => formatOneNews(oneNews))
}

/**
 *
 * @param oneNews
 * @returns {{button: (string|null), image: (*|null), shortDescription: *, href: (string|null), title: *, isLarge: boolean}}
 */
export function formatOneNews(oneNews) {
	return {
		title: oneNews.title,
		shortDescription: oneNews.description,
		isLarge: oneNews.sticky,
		image: oneNews.image?.cachedPath,
		button: !!oneNews.link ? (oneNews.buttonDescription || "Je découvre") : null,
		href: oneNews.link
	}
}

export function formatAddress(address, asMarkdown = true) {

	const {
		company,
		firstName,
		lastName,
		street,
		postcode,
		city,
		phoneNumber
	} = address

	let string = company ? (asMarkdown ? `**${ company }**  \n` : `${ company }  \n`) : ''
	string += ( firstName || lastName ) ? `${ firstName } ${ lastName }  \n` : ''
	string += `${ street }  \n`
	string += `${ postcode } ${ city }`
	string += phoneNumber ? `\n\n${ phoneNumber }` : ''

	return string
}

export function parseGiftAddresses(cart, giftAddresses) {
	if (isNull(cart) || isNull(giftAddresses) )
		return ""

	const addresses = []

	cart.items.forEach(item => {
		if (isArray(giftAddresses[item.id])) {
			giftAddresses[item.id].forEach(gift => {
				if (gift.differentAddress) {
					addresses.push(`Livraison différente pour un "${gift.address.name}" :\n\n${formatAddress(gift.address, false)}`)
				}
			})
		}
	})

	return addresses
}

export function formatUserFrom(userFrom) {
	if (isUndefined(userFrom.source))
		return ""

	const other = userFrom.other !== "" ? ` (${userFrom.other})` : ""

	return `Source : ${userFrom.source}${other}`
}

export function formatCheckoutNotes(...args) {
	const notes = []
	args.forEach(arg => {
		if (isArray(arg)) {
			arg.forEach(item => {
				notes.push(item)
			})
		} else {
			notes.push(arg)
		}
	})

	return notes.join("\n\n–––\n\n")
}
