
import { Form, Field } from 'formik'

import { InputField } from 'components/form/fields'
import Layout, { LayoutItem } from 'components/layout'

export function AddressFields( props ) {

	const {
		name
	} = props

	return (
		<>
			<Layout size="normal" className="u-margin-bottom-normal">
				<LayoutItem
					widths={ {
						'tablet': '1/2'
					} }
				>
					<Field
						component={ InputField }
						name={ `${name}.firstName` }
						label="Prénom"
						isRequired
					/>
				</LayoutItem>
				<LayoutItem
					widths={ {
						'tablet': '1/2'
					} }
				>
					<Field
						component={ InputField }
						name={ `${name}.lastName` }
						label="Nom"
						isRequired
					/>
				</LayoutItem>
			</Layout>
			<Field
				component={ InputField }
				name={ `${name}.company` }
				label="Société"
			/>
			<Field
				component={ InputField }
				name={ `${name}.street` }
				label="Rue"
				isRequired
			/>
			<Layout size="normal" className="u-margin-bottom-normal">
				<LayoutItem
					widths={ {
						'tablet': '1/3'
					} }
				>
					<Field
						component={ InputField }
						name={ `${name}.postcode` }
						label="Code postal"
						isRequired
					/>
				</LayoutItem>
				<LayoutItem
					widths={ {
						'tablet': '2/3'
					} }
				>
					<Field
						component={ InputField }
						name={ `${name}.city` }
						label="Ville"
						isRequired
					/>
				</LayoutItem>
			</Layout>
			<Field
				component={ InputField }
				name={ `${name}.phoneNumber` }
				label="Téléphone"
				isRequired
			/>
		</>
	)
}
