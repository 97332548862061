
import classNames from 'classnames'
import React, { useEffect } from 'react'
import Link from 'next/link'

import Title from 'components/title'
import Card, { CardContent } from 'components/card'
import { SectionLayout, SectionContent } from 'components/section'
import Markdown from "components/markdown"
import { Address } from 'components/shop'
import Layout, { LayoutItem } from 'components/layout'
import List, { ListItem } from 'components/list'
import Marker from 'components/marker'
import Button from 'components/button'

import { useMap } from 'contexts/MapContext'

import { text } from 'libs/text-manipulation'
import { isEmpty, isNull } from 'libs/utils'
import { formatTaxonUri, formatTaxonUriAs } from 'libs/formatters'

export function BreadCrumb( props ) {

	const {
		ancestors
	} = props

	if( ! ancestors )
		return null

	return (
		<span className="o-breadcrumb">
			{ ancestors.filter( a => a ).map( (ancestor, index) => (
				<Link
					key={ index }
					href={ formatTaxonUriAs( ancestor ) }
					as={ formatTaxonUri( ancestor ) }
				>
					<a>{ text( ancestor.name ) }</a>
				</Link>
			) ) }
		</span>
	)
}

export function Divider( props ) {
	const {
		isGrow,
		size,
		color,
		title,
		isPrimary,
		className
	} = props

	return (
		<div
			className={ classNames(
				'o-divider',
				{
					'o-divider--grow': isGrow,
					'o-divider--primary': isPrimary,
					'o-divider--title': title,
					[`o-divider--${size}`]: size,
					[`u-background-color--${color}`]: color
				},
				className
			) }
		>
			{ title && (
				<div
					className={ classNames(
						'o-divider__title',
						{
							[`u-color--${color}`]: color
						}
					) }
				>
					{ title }
				</div>
			) }
		</div>
	)
}

export function Legend( props ) {

	const {
		isSummary,
		size,
		align,
		children,
		className
	} = props

	return (
		<div
			className={ classNames(
				'o-legend',
				{
					[`u-text-align--${align}`]: align,
					[`u-font-size--${size}`]: size,
					'o-legend--summary': isSummary
				},
				className
			) }
		>
			{ children }
		</div>
	)
}

export function Content( props ) {

	const {
		isSummary,
		isPost,
		isSection,
		color,
		children,
		align,
		className
	} = props

	return (
		<div
			className={ classNames(
				'u-content',
				{
					'u-content--summary': isSummary,
					'u-content--post': isPost,
					'u-content--section': isSection, // TODO: change to list
					[`u-text-align--${align}`]: align,
					[`u-color--${color}`]: color
				},
				className
			) }
		>
			{ children }
		</div>
	)
}

export function PostCard( props ) {

	const {
		isLarge,
		image,
		button,
		href,
		as,
		title,
		shortDescription,
		meta
	} = props

	return (
		<Card
			layout={ isLarge ? '1/1' : '1/2' }
			image={ image }
			meta={ meta }
			button={ button }
			href={ href }
			as={ as }
		>
			<CardContent>
				{ title && (
					<Title tag="h3" size="medium">{ title }</Title>
				) }
				{ shortDescription && (
					<Markdown>{ shortDescription }</Markdown>
				) }
			</CardContent>
		</Card>
	)
}

export function Meta( props ) {

	const {
		value,
		button
	} = props

	return (
		<div className="c-meta">
			<span className="c-meta__value">
				{ value }
			</span>
			{ button }
		</div>
	)
}

export function PageContent( props ) {

	const {
		page
	} = props

	return (
		<SectionLayout
			isFlex
			align="center"
		>
			<SectionContent
				isPost
			>
				{ page.content }
			</SectionContent>
		</SectionLayout>
	)
}

export function Stores( props ) {
	const {
		items
	} = props

	const {
		setItems,
		setMapOption,
		panToMarkerItem
	} = useMap()

	useEffect(
		() => {			
			setMapOption('onMarkerClick', scrollToListItem)
		},
		[]
	)

	useEffect(
		() => {
			if (isNull(items))
				return

			setItems(items)
		},
		[items]
	)

	const handleShowMarker = index => {
		panToMarkerItem(index)
	}

	const scrollToListItem = index => {
		
		window.scrollTo({
			top: (
				window.scrollY 
				+ document.getElementById(`map-list-item-${index}`).getBoundingClientRect().top
				- document.getElementById('site-header').offsetHeight
				- document.getElementById('retailers-map').offsetHeight
			),
			behavior: "smooth"
		})
	}

	if (isNull(items) || isEmpty(items))
		return null

	return (
		<List>
			{ items.map((item, index) => (
				<ListItem key={ index } id={ `map-list-item-${index}` }>
					<Layout
						isFlex
						size="small"
						isRow 
						isAuto 
						vAlign="middle"
					>
						<LayoutItem>
							<Marker
								onClick={ handleShowMarker.bind(null, index) }
							>
								{ index + 1 }
							</Marker>
						</LayoutItem>
						<LayoutItem isPrimary>
							<Title tag="h3" size="normal" className="u-margin-bottom-none">{ item.title }</Title>
							<Content isSummary>
								<Address { ...item.address }/>
							</Content>
						</LayoutItem>
						<LayoutItem>
							<Button
								type="button"
								size="small"
								color="gray"
								onClick={ handleShowMarker.bind(null, index) }
							>
								Voir sur la carte
							</Button>
						</LayoutItem>
					</Layout>
				</ListItem>
			))}
		</List>
	)
}
