
import { useEffect, useRef, useState } from 'react'
import Router from 'next/router'

import { isString, isServerSide } from '../libs/utils'

const pageSelector = 'o-page__container'

// To the top
export function scrollToTop() {
	window.scrollTo( 0, 0 )
}

// Next section
export function scrollToNext( domElem ) {

	if( ! domElem )
		return false

	if( isString( domElem ) ) {
		domElem = document.querySelector( domElem )
	}

	if( domElem && ! domElem.parentNode.classList.contains(pageSelector) ) {
		domElem = getParentInPage( domElem )
	}

	if( ! domElem )
		return false

	window.scrollTo( 0, domElem.nextElementSibling.offsetTop )
}

// Next section
export function scrollTo( domElem, offset = 0 ) {

	if( ! domElem )
		return false

	if( isString( domElem ) ) {
		domElem = document.querySelector( domElem )
	}

	if( ! domElem )
		return false

	window.scrollTo( 0, domElem.offsetTop - offset )
}

/**
 * Get the last parent of elem in page (pageSelector)
 *
 * @see: https://gomakethings.com/climbing-up-and-down-the-dom-tree-with-vanilla-javascript/
 */
export function getParentInPage( domElem, parentSelector ) {

	parentSelector = parentSelector || pageSelector

	// Element.matches() polyfill
	if (!Element.prototype.matches) {
		Element.prototype.matches =
			Element.prototype.matchesSelector ||
			Element.prototype.mozMatchesSelector ||
			Element.prototype.msMatchesSelector ||
			Element.prototype.oMatchesSelector ||
			Element.prototype.webkitMatchesSelector ||
			function(s) {
				var matches = (this.document || this.ownerDocument).querySelectorAll(s),
					i = matches.length;
				while (--i >= 0 && matches.item(i) !== this) {}
				return i > -1;
			}
	}

	parent = null

	// Get matching parent elements
	for ( ; domElem && domElem !== document && ! domElem.classList.contains( parentSelector ); domElem = domElem.parentNode ) {
		parent = domElem
	}

	return parent
}

/**
 * Use intersection Observer
 *
 * @see: https://medium.com/the-non-traditional-developer/how-to-use-an-intersectionobserver-in-a-react-hook-9fb061ac6cb5
 */
export function useIntersect( { root = null, rootMargin, threshold = 0 } ) {

	const [entry, updateEntry] = useState( {} )
	const [node, setNode] = useState( null )

	const observer = useRef(
		! isServerSide() && 'IntersectionObserver' in window
		? new IntersectionObserver( ([entry]) => updateEntry( entry ),
			{
				root,
				rootMargin,
				threshold
			}
		)
		: null
	)

	useEffect(
		() => {
			const {
				current: currentObserver
			} = observer
			
			if( currentObserver ) {
				currentObserver.disconnect()

				if( node ) {
					currentObserver.observe( node )
				}

				return () => currentObserver.disconnect()
			}
		},
		[node]
	)

	return [setNode, entry]
}

/**
 * Update location.hash without scrolling
 *
 * @params id string
 * @see: https://gist.github.com/sunny/481146
 */
export function changeHashWithoutScrolling( id = '' ) {

	// id is different from current hash
	if( id !== window.location.hash.replace(/^.*#/, '') ) {

		const pathBase = Router.router.pathname.replace(/#.+$/, '')
		const asBase = Router.router.asPath.replace(/#.+$/, '')

		if( id ) {
			const elem = document.getElementById( id )

			if( elem ) elem.id = id + '-tmp'
			Router.replace( `${pathBase}#${id}`, `${asBase}#${id}`, { shallow: true } )
			if( elem ) elem.id = id

		} else {
			Router.replace( pathBase, asBase, { shallow: true } )
		}
	}
}
