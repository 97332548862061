
import classNames from 'classnames'
import { useState, useRef, useEffect } from 'react'
import { Formik, Form, Field, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { useAlert } from 'react-alert'
import PropTypes from 'prop-types'

import Button from 'components/button'
import Card, { CardContent } from 'components/card'
import Carousel from '../components/carousel'
import { Grid } from 'components/layout'
import { Meta, Divider, Legend, PostCard } from 'components/generic'
import MarkDown, { ContentMarkdown } from 'components/markdown'
import { Icon, Figure } from 'components/media'
import Title, { Subtitle } from 'components/title'
import Section, { SectionLayout, SectionContent, SectionNavbar } from 'components/section'
import Layout, { LayoutItem } from 'components/layout'
import List, { ListItem } from 'components/list'
import Tabs from 'components/tabs'
import { ToggleField, InputField } from 'components/form/fields'
import { AddressFields } from 'components/form/composed'

import { formatAddress, formatImage } from 'libs/formatters'
import { text } from 'libs/text-manipulation'
import { scrollTo } from 'libs/navigation'
import { isEmpty, isObject, isNull, isUndefined, inTaxon, isEvent, productHasMultiplePrices, parsePrice, getCartItemDescription, hasOptionFormat, getAvailableVariants, hasProductStock, getVariantStock, hasVariantStock, getVariantDescription, productVariantsCompare, responseCallback, getProductTaxNames, getGiftShipment, isPickUpShipment, getErrorTranslation, isPastEvent, isSimpleProduct, getProductQuantityParams, range } from '../libs/utils'

import { useCart } from '../contexts/CartContext'
import Wrapper from 'components/wrapper'
import { PASS_TAXON, STAY_TAXON, TICKET_TAXON } from 'libs/variables'
import { Select } from 'components/form/elements'

const __priceFrom = 'À partir de'
const __priceUnit = ' €'

export function GridProducts( props ) {

	const {
		items,
		children
	} = props

	return (
		<Grid
			card={ ProductCard }
			items={ items }
		>
			{ children }
		</Grid>
	)
}

export function ProductCard( product ) {

	const {
		isLarge,
		image,
		button,
		href,
		as,
		onClick,
		title,
		shortDescription,
		secondaryDescription,
		variants
	} = product

	return (
		<Card
			layout={ isLarge ? '1/1' : '1/2' }
			image={ image }
			meta={
				! isEmpty( variants ) && (
					hasProductStock( product )
					? <ProductPrice product={ product } />
					: <Legend isSummary size="tiny"><span className="u-color--error">{ isEvent( product ) ? 'Complet' : 'Indisponible' }</span></Legend>
				)
			}
			button={ button }
			href={ href }
			as={ as }
			onClick={ onClick }
		>
			{ (title || shortDescription || secondaryDescription) && (
				<CardContent>
					{ title && (
						<Title tag="h3" size="medium">{ title }</Title>
					) }
					{ secondaryDescription && ! inTaxon( product, TICKET_TAXON ) && (
						<Subtitle tag="h4" size="normal">{ secondaryDescription }</Subtitle>
					) }
					{ shortDescription && inTaxon( product, TICKET_TAXON ) && (
						<ContentMarkdown isSummary>{ shortDescription }</ContentMarkdown>
					) }
					{ shortDescription && ! secondaryDescription && (
						<ContentMarkdown isSummary>{ shortDescription }</ContentMarkdown>
					) }
				</CardContent>
			) }
		</Card>
	)
}

export function RelativeProductCard( product ) {

	const {
		image,
		button,
		href,
		as,
		title,
		shortDescription,
		secondaryDescription,
		variants
	} = product

	return (
		<Card
			image={ image }
			meta={
				! isEmpty( variants ) && (
					hasProductStock( product )
					? <ProductPrice product={ product } />
					: <Legend isSummary size="tiny"><span className="u-color--error">{ isEvent( product ) ? 'Complet' : 'Indisponible' }</span></Legend>
				)
			}
			button={ button }
			href={ href }
			as={ as }
			widths={ {
				mobile: '1/2',
				tablet: '1/3',
				'tablet-cart': '1/2',
				wide: '1/4',
				'wide-cart': '1/3'
			} }
		>
			{ (title || shortDescription || secondaryDescription) && (
				<CardContent>
					{ title && (
						<Title tag="h3" size="medium">{ title }</Title>
					) }
					{ secondaryDescription && ! inTaxon( product, TICKET_TAXON ) && (
						<Subtitle tag="h4" size="normal">{ secondaryDescription }</Subtitle>
					) }
					{ shortDescription && inTaxon( product, TICKET_TAXON ) && (
						<ContentMarkdown isSummary>{ shortDescription }</ContentMarkdown>
					) }
				</CardContent>
			) }
		</Card>
	)
}

export function OrderProductItem( item ) {
	const {
		product
	} = item

	return (
		<Layout
			isFlex
			isAuto
			isRow
			size="normal"
			vAlign="middle"
		>
			<LayoutItem>
				<Figure
					isRounded
					size="huge"
					ratio="1:1"
					image={ formatImage( product.images ) }
				/>
			</LayoutItem>
			<LayoutItem
				isPrimary
			>
				<Title size="medium">{ product.name }</Title>
				<Subtitle size="normal">{ getCartItemDescription( item ) }</Subtitle>
			</LayoutItem>
		</Layout>
	)
}

export function CheckoutProductItem( item ) {

	const {
		product,
		quantity,
		total
	} = item

	const price = 'variants' in product ? product.variants[0].price.current : total

	const image = formatImage(product.images)

	return (
		<Layout isFlex isRow isAuto size="normal" vAlign="middle">
			{ !!image && (
				<LayoutItem>
					<Figure
						isRounded
						size="huge"
						ratio="1:1"
						image={ image }
					/>
				</LayoutItem>
			) }
			<LayoutItem isPrimary>
				<div>
					<Title size="medium">{ product.name }</Title>
					<Subtitle size="normal">{ getCartItemDescription( item ) }</Subtitle>
				</div>
			</LayoutItem>
			<LayoutItem>
				<Quantity amount={ quantity }/>
			</LayoutItem>
			<LayoutItem>
				<Price size="medium" amount={ price }/>
			</LayoutItem>
		</Layout>
	)
}

export function CheckoutGiftItem( props ) {

	const {
		values
	} = useFormikContext()

	const {
		cart,
		item,
		index
	} = props

	const {
		id,
		product
	} = item

	const shipment = getGiftShipment( cart, product.taxons.main.code )

	return (
		<>
			<Layout isFlex isRow isAuto size="normal" vAlign="middle">
				<LayoutItem>
					<Figure
						isRounded
						size="huge"
						ratio="1:1"
						image={ formatImage( product.images ) }
					/>
				</LayoutItem>
				<LayoutItem isPrimary>
					<Title size="medium">{ product.name }</Title>
					<Subtitle size="normal">{ getCartItemDescription( item ) }</Subtitle>
				</LayoutItem>
				<LayoutItem
					align="right"
				>
					<Price size="medium" amount={ shipment.amount }/>
					<Legend isSummary>{ shipment.name }</Legend>
				</LayoutItem>
			</Layout>
			{ ! isPickUpShipment( cart ) && inTaxon( product, PASS_TAXON ) && (values.giftAddresses.length > 1 || values.giftAddresses[id] && values.giftAddresses[id].length > 1) && (
				<>
					<Divider/>
					<Field
						component={ ToggleField }
						name={ `giftAddresses.${id}.${index}.differentAddress` }
						label="Livrer ce cadeau à une personne différente ?"
					/>
					{ values.giftAddresses && values.giftAddresses[id][index] && values.giftAddresses[id][index].differentAddress && (
						<AddressFields
							name={ `giftAddresses.${id}.${index}.address` }
						/>
					) }
				</>
			) }
		</>
	)
}

export function PriceText( { amount } ) {
	return text( `${parsePrice( amount )}${__priceUnit}` )
}

export function Price( props ) {

	const {
		amount,
		original,
		isFrom,
		prefix,
		suffix,
		size,
		inNumber,
		className
	} = props

	if( isNull( amount ) || isUndefined( amount ) )
		return null

	let price = (
		<>
			{ prefix && (
				<span className="u-price__prefix">{ prefix }</span>
			) }
			{
				amount === 0 && ! inNumber
				? <small className="u-price__free">Gratuit</small>
				: <PriceText amount={ amount }/>
			}
			{ suffix && (
				<span className="u-price__suffix">{ suffix }</span>
			) }
		</>
	)

	if( isFrom ) {
		price = (
			<>
				<span className="u-price__from">{ __priceFrom }</span>
				{ price }
			</>
		)
	}

	return(
		<span
			className={ classNames(
				'u-price',
				{
					[`u-font-size--${size}`]: size
				},
				className
			) }
		>
			{ original && original !== amount && ! isFrom
				? (
					<>
						<del>
							<PriceText amount={ original }/>
						</del>
						{ ' ' }
						<ins>
							{ price }
						</ins>
					</>
				)
				: price
			}
		</span>
	)
}

Price.propTypes = {
	amount: PropTypes.number,
	original: PropTypes.number,
	isFrom: PropTypes.bool,
	size: PropTypes.string
}

export function ProductPrice( props ) {

	const {
		variant,
		product,
		size,
		prefix,
		suffix,
	} = props

	const {
		variants
	} = product

	if( isEmpty( variants ) )
		return null

	const variantsArray = Object.values(variants).sort( productVariantsCompare )
	const current = variant || variantsArray[0]

	return (
		<Price
			amount={ ! isEmpty( current.price ) ? current.price.current : null }
			original={ ! isEmpty( current.originalPrice ) ? current.originalPrice.current : null }
			isFrom={ isEmpty( variant ) && ! isEmpty( variants ) && productHasMultiplePrices( variantsArray ) }
			size={ size }
			prefix={ prefix }
			suffix={ suffix }
		/>
	)
}

ProductPrice.propTypes = {
	variant: PropTypes.object,
	product: PropTypes.object,
	size: PropTypes.string
}

export function Quantity( props ) {

	const {
		amount
	} = props

	return (
		<span className="u-quantity">
			{ amount }
			<Icon
				name="multiply"
				aria-label="x"
			/>
		</span>
	)
}

export function Address( props ) {

	const {
		firstName,
		lastName,
		street,
		placeholder
	} = props

	if( ! firstName && ! lastName && ! street ) {
		return <p>{ placeholder }</p>
	}

	// return (
	// 	<>
	// 		<p>
	// 			{ company && ( <strong>{ company }<br/></strong> ) }
	// 			{ ( firstName || lastName ) && ( <>{ firstName } { lastName }<br/></> ) }
	// 			{ street }<br/>
	// 			{ postcode } { city }
	// 		</p>
	// 		{ phoneNumber && ( <p>{ phoneNumber }</p> ) }
	// 	</>
	// )

	return (
		<MarkDown>
			{ formatAddress( props ) }
		</MarkDown>
	)
}

Address.propTypes = {
	id: PropTypes.number,
	name: PropTypes.string,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	phoneNumber: PropTypes.string,
	company: PropTypes.string,
	country: PropTypes.string,
	province: PropTypes.string,
	street: PropTypes.string,
	city: PropTypes.string,
	postcode: PropTypes.string,
	placeholder: PropTypes.string
}

Address.defaultProps = {
	placeholder: 'Aucune adresse'
}

export function RelativeProducts( props ) {

	const {
		title,
		products
	} = props

	if( isEmpty( products ) )
		return null

	return(
		<Section>
			<Wrapper>
				<Carousel
					title={ title }
					backgroundColor="light-gray"
				>
					<Layout>
						{ products && products.map( ( item, index ) => {
							return (
								<RelativeProductCard
									key={ index }
									{ ...item }
								/>
							)
						} ) }
					</Layout>
				</Carousel>
			</Wrapper>
		</Section>
	)
}

RelativeProducts.defaultProps = {
	title: "Envie de plus d'options ?",
	products: []
}

export function ProductVariants( props ) {

	const {
		product
	} = props

	// TEMP
	if( product.code === 'SibyllinePass-siby-pass-sm' )
		return <div className="u-margin-bottom"/>

	const [isTicket, setIsTicket] = useState( true )
	const toggleFormat = () => setIsTicket( ! isTicket )

	const variants = getAvailableVariants( product.variants, {name: 'format', value: isTicket ? 'Enveloppe cadeau' : 'E-billet' } ) // TODO: use code instead of name

	if( isEmpty( variants ) )
		return null

	const productTaxNames = getProductTaxNames( product )

	return (
		<SectionLayout
			id="options"
			isFlex
			align="center"
			navbar={ {
				title: <Title size="normal" color="primary">{ product.title }</Title>,
				meta: (
					<Button
						isText
						isFlushed
						size="small"
						iconRight="chevron-up"
						onClick={ () => scrollTo( '#decouvrir' ) }
					>
						Infos
					</Button>
				)
			} }
		>
			<SectionContent>
				{ variants.every( variant => hasOptionFormat( variant ) ) && (
					<>
						<Divider size="medium"/>
						<Layout
							isFlex
							isAuto
							vAlign="middle"
							className="u-margin-bottom-medium"
						>
							<LayoutItem isPrimary>
								<Subtitle size="normal">Mode de livraison</Subtitle>
							</LayoutItem>
							<LayoutItem>
								<Tabs
									size="small"
									color="gray"
									onClick={ () => toggleFormat() }
									active={ isTicket ? 'ticket' : 'eticket' }
									items={ {
										'ticket': 'Enveloppe cadeau',
										'eticket': 'E-billet'
									} }
								/>
							</LayoutItem>
						</Layout>
					</>
				) }

				<List isPrimary>
					{ variants.map( ( variant, index ) => (
						<ListItem
							key={ index }
						>
							<ProductVariant
								variant={ variant }
								product={ product }
								productTaxNames={ productTaxNames }
							/>
						</ListItem>
					) ) }

					{ ! isEmpty( productTaxNames ) && (
						<Legend
							isSummary
							align="right"
							size="tiny"
							className="u-position-absolute u-right u-padding-top-tiny"
						>
							{ productTaxNames.map( (name, index) => `${'*'.repeat(index+1)} ${name}` ).join(', ') }
						</Legend>
					) }
				</List>

			</SectionContent>
		</SectionLayout>
	)
}

export function ProductVariant( props ) {

	const alert = useAlert()

	const {
		isLoading,
		addItem,
		getProductItems,
		getItemQuantityLeft,
		hasItemMaxQuantity
	} = useCart()

	const {
		variant,
		product,
		productTaxNames
	} = props

	const isSimple = isSimpleProduct( product )

	const quantityParams = getProductQuantityParams( product )
	const [quantitySelected, setQuantitySelected] = useState( !! quantityParams && quantityParams.default || 1 )

	const handleChangeQuantity = e => {
		setQuantitySelected( e.currentTarget.value )
	}

	const addToCart = async () => {
		const config = {
			productCode: product.code,
			quantity: quantitySelected
		}

		if ( ! isSimple ) {
			config.variantCode = variant.code
		}

		const response = await addItem( config )

		responseCallback(
			response,
			response => null,
			error => alert.error( 'Un problème est survenu, merci de rééssayer ultérieurement.' )
		)
	}

	const description = getVariantDescription( variant, product.taxons.main.code )

	const stock = getProductItems( product ).length > 0 ? getItemQuantityLeft( product, variant ) : getVariantStock( variant )

	return (
			<Layout
				isFlex
				isAuto
				isRowMobile
				size="medium"
				vAlign="middle"
			>
				<LayoutItem isPrimary>
					<Title size="medium">{ variant.name || product.name }</Title>
					{ description && <Subtitle size="small">{ description }</Subtitle> }
				</LayoutItem>
				{ variant.isTracked && (
					<LayoutItem>
						<Legend
							isSummary
							align="right"
							size="tiny"
						>
							{ isPastEvent( product )
								? (
									<span className="u-color--error">Terminé</span>
								)
								: (
									stock === 0
									? <span className="u-color--error">{ isEvent( product ) ? 'Complet' : 'Indisponible' }</span>
									: (
										stock === 1
										? `${stock}\xa0disponible`
										: `${stock}\xa0disponibles`
									)
								)
							}
						</Legend>
					</LayoutItem>
				) }
				{ quantityParams && stock > 0 && (
					<LayoutItem>
						<Select
							value={ quantitySelected.toString() }
							size="small"
							onChange={ handleChangeQuantity }
							options={ range( quantityParams.min, quantityParams.max < stock ? quantityParams.max : stock ).map( value => ( {
								label: value.toString(),
								value: value.toString()
							} ) ) }
						/>
					</LayoutItem>
				) }
				<LayoutItem>
					<Meta
						value={
							<ProductPrice
								variant={ variant }
								product={ product }
								suffix={ !! ('taxCategory' in variant && ! isEmpty( productTaxNames )) && (
									<span className="u-font-weight--normal u-color--gray">
										{ ` ${'*'.repeat(productTaxNames.indexOf(variant.taxCategory.name)+1)}` }
									</span>
								) }
							/>
						}
						button={
							variant.isTracked && ! hasVariantStock( variant ) ||
							hasItemMaxQuantity( product, variant ) ||
							isPastEvent( product )
							? (
								<Button
									id="product-add-full"
									className="c-meta__button"
									isDisabled
									isLoading={ isLoading === `addItem-${ isSimple ? product.code : variant.code }` }
								>
									Ajouter
								</Button>
							)
							: (
								<Button
									id="product-add"
									className="c-meta__button"
									color="primary"
									onClick={ addToCart }
									isLoading={ isLoading === `addItem-${ isSimple ? product.code : variant.code }` }
								>
									Ajouter
								</Button>
							)
						}
					/>
				</LayoutItem>
			</Layout>
	)
}

export function ProductContent( props ) {

	const {
		product
	} = props

	return (
		<>
			<SectionLayout
				id="decouvrir"
				isFlex
				align="center"
				navbar={ {
					title: <Title size="normal" color="primary">{ product.title }</Title>,
					meta: (
						<Button
							isText
							isFlushed
							size="small"
							iconRight="chevron-down"
							onClick={ () => scrollTo( '#options' ) }
						>
							<ProductPrice product={ product }/>
						</Button>
					)
				} }
			>
				<SectionContent>
					<ContentMarkdown
						isPost
					>
						{ product.description }
					</ContentMarkdown>

				</SectionContent>
			</SectionLayout>

			<ProductVariants
				product={ product }
			/>
		</>
	)
}

export function GiftCard( props ) {

	if (![PASS_TAXON, TICKET_TAXON].includes(props.code)) {
		return <PostCard {...props} />
	}

	const {
		isLarge,
		image,
		button,
		href,
		as,
		onClick,
		title,
		description,
		shortDescription
	} = props

	const idPart = as.replace(/\//g, '-')

	return (
		<Card
			layout={ isLarge ? '1/1' : '1/2' }
			image={ image }
			meta={
				<>
					<Button
						id={ `gift-card${idPart}-discover` }
						color="primary"
						size="small"
						href={ href }
						as={ as }
						onClick={ onClick }
						className="u-margin-right-small"
					>
						Découvrir
					</Button>
					<Button
						id={ `gift-card${idPart}-use` }
						size="small"
						href={ href ? `${href}#utiliser` : null }
						as={ as ? `${as}#utiliser` : null }
						onClick={ e => e.stopPropagation() }
					>
						Utiliser
					</Button>
				</>
			}
		>
			{ (title || shortDescription) && (
				<CardContent>
					{ title && (
						<Title tag="h3" size="medium">{ title }</Title>
					) }
					{ shortDescription && (
						<p>{ shortDescription }</p>
					) }
				</CardContent>
			) }
		</Card>
	)
}

export function TicketForm( props ) {

	const {
		product
	} = props

	// Check if product is an event and is future
	if( ! isEvent( product ) || isPastEvent( product ) )
		return null

	// Check if product is available
	if( ! hasProductStock( product ) )
		return null

	const variant = Object.values( product.variants )[0]

	const {
		addItem,
		addCoupon
	} = useCart()

	const buttonRef = useRef( null )
	// const [modal, setModal] = useState( false )

	// Component status
	const [active, setActive] = useState( false )
	const handleClickActive = () => setActive( true )

	// Force input validation on button click
	const triggerInputValidation = () => {
		if( buttonRef.current ) {
			const input = buttonRef.current.previousSibling
			input.focus()
			input.blur()
			if( error !== '' ) {
				input.addEventListener( 'focus', resetInputValidation, false )
			}
		}
	}
	const resetInputValidation = e => {
		setError( '' )
		e.target.removeEventListener( 'focus', resetInputValidation, false )
	}

	// Submit form
	const handleSubmit = ( values, { setSubmitting, resetForm } ) => {

		if( values.coupon ) {

			// // Offer choice to user: add coupon or both product & coupon
			// if( hasProduct( product ) ) {
			// 	setModal( true )
			//
			// 	const confirmResponse = new Promise(  )
			//
			// // Add product & coupon to cart
			// } else {
				setBoth( values, { setSubmitting, resetForm } )
			// }
		}
	}

	// Add coupon to cart
	const setCoupon = async ( values, { setSubmitting } ) => {
		console.log('setCoupon success');
		const couponResponse = await addCoupon( values.coupon )

		responseCallback(
			couponResponse,
			response => {
				console.log('couponResponse success');
				setSubmitting( false )
				setError( '' )
				setActive( false )
			},
			error => {
				setSubmitting( false )
				parseError( error )
			}
		)
	}

	// Add product, then add coupon to cart
	const setBoth = async ( values, { setSubmitting, resetForm } ) => {

		// Add associated product/variant
		const productResponse = await addItem( {
			productCode: product.code,
			quantity: 1,
			variantCode: variant.code,
			promotionCoupon: values.coupon
		} )

		responseCallback(
			productResponse,
			// Add coupon
			response => {
				setSubmitting( false )
				resetForm()
				setActive( false )
			},
			error => {
				setSubmitting( false )
				parseError( error )
			}
		)
		// responseCallback(
		// 	productResponse,
		// 	// Add coupon
		// 	async response => setCoupon( values, { setSubmitting } ),
		// 	error => {
		// 		setSubmitting( false )
		// 		parseError( error )
		// 	}
		// )
	}

	// Custom form errors
	const [error, setError] = useState( '' )
	const parseError = error => {
		let errorMessage = ''
		if( isObject( error ) ) {
			if( 'errors' in error ) {
				errorMessage = Object.values( error.errors ).map( values => values.map( er => getErrorTranslation( er ) ).join(', ') ).join(', ')
			} else if( 'message' in error ) {
				errorMessage = error.message
			} else {
				errorMessage = 'Une erreur est survenue'
			}
		} else {
			errorMessage = error
		}

		setError( errorMessage )
	}
	useEffect(
		triggerInputValidation,
		[error]
	)

	return (
		<>
			<Divider
				isPrimary
				title="où"
				size="huge"
			/>
			<div
				className="u-margin-bottom"
			>
				{ active
					? (
						<Formik
							initialValues={ { coupon: '' } }
							validationSchema={ Yup.object( {
								coupon: Yup.string()
									.test( {
										name: 'is-valid',
										message: '${error}',
										test: value => error === '',
										params: {
											error
										}
									} )
									.required( 'Veuillez renseigner le code de votre Sibylline Ticket' )
							} ) }
							onSubmit={ handleSubmit }
						>
							{ ( {
								values,
								isSubmitting
							} ) => (
								<Form>
									{ console.log('isSubmitting', isSubmitting) }
									<Field
										component={ InputField }
										name="coupon"
										placeholder="Votre code Sibylline Ticket"
										isRequired
										isDisabled={ isSubmitting }
										after={
											<Button
												id="ticket-add"
												ref={ buttonRef }
												isPrimary
												isLoading={ isSubmitting }
												isDisabled={ ! values.coupon }
											>
												Valider
											</Button>
										}
									/>
									{/* modal && (
										<Modal
											active={ true }
											hasOverlay
											widths={ {
												mobile: '1/2',
												tablet: '1/3',
												desktop: '1/4',
												wide: '1/5'
											} }
										>
											<Card
												hasDivider
											>
												<CardContent>
													<Title size="medium">Ce produit est déjà dans votre panier</Title>
													<Markdown>
														{ `Voulez-vous ajouter uniquement votre Sibylline Ticket ou les 2 ?` }
													</Markdown>
												</CardContent>
												<CardFooter
													align="right"
												>
													<Buttons>
														<Button
															id="modal-coupon-confirm-both"
															size="small"
															onClick={ () => {
																console.log('click submittingForm', values, submittingForm);
																setModal( false )
																// setBoth( values, { setSubmitting: submittingForm } )
															} }
														>
															Les 2
														</Button>
														<Button
															id="modal-coupon-confirm-coupon"
															size="small"
															isPrimary
															onClick={ () => {
																console.log('click submittingForm', values, submittingForm);
																setModal( false )
																// setBoth( values, { setSubmitting: submittingForm } )
															} }
														>
															Le Sibylline Ticket
														</Button>
													</Buttons>
												</CardFooter>
											</Card>
										</Modal>
									) */}
								</Form>
							) }
						</Formik>
					)
					: (
						<Button
							isPrimary
							isFullWidth
							onClick={ handleClickActive }
						>
							Utiliser votre Sibylline Ticket
						</Button>
					)
				}
			</div>
		</>
	)
}
