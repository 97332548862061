
import classNames from 'classnames'
import React from 'react'
import Link from 'next/link'
import ReactMarkdown from 'react-markdown'

import { Content } from 'components/generic'

import { text } from 'libs/text-manipulation'
import { isExternal, parseError } from 'libs/utils'

export default function Markdown( { children, ...options } ) {
	return (
		<ReactMarkdown source={ text( children ) } { ...options } />
	)
}

Markdown.defaultProps = {
	escapeHtml: false,
	linkTarget: ( url, text, title ) => {
		if( isExternal( url ) )
			return '_blank'

		return undefined
	},
	renderers:  {
		link: LinkRenderer
	}
}

export function ContentMarkdown( { options, children, ...props } ) {

	return (
		<Content { ...props }>
			<Markdown { ...options }>
				{ children }
			</Markdown>
		</Content>
	)
}

export function ErrorMarkdown( { message, error } ) {

	return (
		<Markdown>
			{ `${message}${message ? '\n\n' : '' }- ${parseError( error, '\n- ' )}` }
		</Markdown>
	)
}

function LinkRenderer( { children, href, as, className, ...props } ) {

	if( props.target ) {
		return (
			<a
				href={ href }
				className={ classNames(
					// 'o-link--external',
					className
				) }
				{ ...props }
			>
				{ children }
			</a>
		)
	}

	return (
		<Link href={ href } as={ as }>
			<a
				className={ classNames(
					// 'o-link--internal',
					className
				) }
				{ ...props }
			>
				{ children }
			</a>
		</Link>
	)
}
