import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CSS_BREAKPOINT_SEPARATOR } from 'libs/variables'
import Loader from './loader'

// inuitcss vars
const figureBpName = 'tablet'

export function Figure( props ) {

	const {
		image,
		alt,
		size,
		ratio, // default 1:1
		hasOverlay,
		isRounded,
		isGrow,
		caption,
		className,
		children
	} = props

	let {
		position,
		overlay
	} = props

	position = hasOverlay ? 'absolute-full' : position
	overlay  = overlay ? overlay : 'light-gray'

	return (
		<figure
			className={ classNames(
				'o-figure',
				{
					[`u-position-${position}`]: position,
					[`u-overlay u-overlay-color--${overlay}`]: hasOverlay,
					// [`u-background-color--${overlay}`]: overlay,
					[`u-width-${size}`]: size,
					'u-rounded': isRounded,
					'o-figure--grow': isGrow
				},
				className
			) }
		>
			<span
				className={ classNames(
					'o-figure__container',
					{
						'o-ratio': ratio,
						[`o-ratio--${ratio}`]: ratio !== '1:1' && ratio !== null && ratio !== undefined,
						[`u-position-${position}`]: position,
						'u-rounded': isRounded
					},
					className
				) }
			>
				{ image && (
					<img
						className={ classNames(
							'o-figure__image',
							{
								'o-ratio__content': ratio,
								[`u-position-${position}`]: position,
								'u-rounded': isRounded
							}
						) }
						src={ image }
						alt={ alt }
						loading="lazy"
					/>
				) }
				{ children }
			</span>
			{ caption && (
				<figcaption className="o-figure__caption">{ caption }</figcaption>
			) }
		</figure>
	)
}

export function Icon( props ) {

	const {
		title,
		alt,
		fa,
		name,
		color,
		size,
		onClick,
		isDisabled,
		className
	} = props

	return(
		<i
			title={ title }
			onClick={ onClick }
			className={ classNames(
				'o-icon',
				{
					'o-icon--fa': fa,
					'o-icon--c': name && name !== 'loader' && ! fa,
					[`o-icon--${name}`]: name && ! fa,
					[`o-icon--size-${size}`]: size,
					[`o-icon--color-${color}`]: color,
					'o-icon--link': onClick,
					'o-icon--disabled': isDisabled
				},
				className
			) }
		>
			{ name === 'loader'
				? <Loader/>
				: fa
			 		? <FontAwesomeIcon icon={ fa } />
					: <span>{ alt }</span>
			}
		</i>
	)
}

export function IconText( props ) {

	const {
		children
	} = props

	return (
		<span className="o-icon__text">
			{ children }
		</span>
	)
}
