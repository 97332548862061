
import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'

export default function Badge( props ) {

	const {
		color,
		label,
		value,
		isRight,
		isLeft,
		className
	} = props

	if( ! value )
		return null

	return (
		<span
			className={ classNames(
				'o-badge',
				{
					[`o-badge--color-${color}`]: color,
					'o-badge--right': isRight,
					'o-badge--left': isLeft
				},
				className
			) }
			aria-label={ label }
		>
			{ value }
		</span>
	)
}

Badge.propTypes = {
	label: PropTypes.string,
	value: PropTypes.oneOfType( [
		PropTypes.string,
		PropTypes.number
	] )
}
