import classNames from 'classnames'
import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'

import { Icon } from './media'

const Button = forwardRef( (props, ref) => {

	const {
		id,
		isPrimary,
		isText,
		isFlushed,
		isFullWidth,
		isDisabled,
		isLoading,
		isChecked,
		disabled,
		color,
		size,
		href,
		as,
		target,
		type,
		onClick,
		title,
		iconLeft,
		iconRight,
		className,
		children
	} = props;

	let classes = classNames(
		'c-btn',
		{
			'c-btn--primary': isPrimary,
			'c-btn--text': isText,
			'c-btn--flush': isFlushed,
			'c-btn--primary': isPrimary,
			'c-btn--checked': isChecked,
			'c-btn--fullwidth': isFullWidth,
			'c-btn--disabled': isDisabled || disabled,
			'c-btn--icon-left': iconLeft,
			'c-btn--icon-right': iconRight,
			[`c-btn--${type}`]: type,
			[`c-btn--${size}`]: size,
			[`c-btn--color-${color}`]: color,
			'u-loader': isLoading,
		},
		className
	)

	const content = (
		<span className="c-btn__container u-loader__container">
			{ iconLeft && <Icon name={ iconLeft } className="c-btn__icon c-btn__icon--left u-loader__value"/> }
			<span id={ id ? `${id}__link` : null } className="c-btn__text u-loader__value">
				{ children }
			</span>
			{ iconRight && <Icon name={ iconRight } className="c-btn__icon c-btn__icon--right u-loader__value"/> }
		</span>
	)


	if( href ) {
		if( target ) {
			return (
					<a
						id={ id } 
						ref={ ref }
						href={ href }
						className={ classes }
						onClick={ onClick }
						title={ title }
						target={ target }
					>
						{ content }
					</a>
			)
		} else {
			return (
				<Link href={ href } as={ as }>
					<a
						id={ id } 
						ref={ ref }
						className={ classes }
						onClick={ onClick }
						title={ title }
					>
						{ content }
					</a>
				</Link>
			)
		}
	}

	return (
		<button
			id={ id } 
			ref={ ref }
			className={ classes }
			disabled={ isDisabled || disabled }
			onClick={ onClick }
			type={ type }
			title={ title }
		>
			{ content }
		</button>
	)
} )

Button.propTypes = {
	isPrimary: PropTypes.bool,
	isFullWidth: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isLoading: PropTypes.bool,
	disabled: PropTypes.bool,
	color: PropTypes.string,
	size: PropTypes.string,
	href: PropTypes.string,
	as: PropTypes.string,
	target: PropTypes.string,
	type: PropTypes.string,
	onClick: PropTypes.func,
	iconLeft: PropTypes.string,
	iconRight: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.node
}

export default Button

export function Buttons( props ) {

	const {
		size,
		children,
		className
	} = props

	return (
		<div 
			className={ classNames( 
				'c-btns',
				{
					[`c-btns--${size}`]: size
				},
				className
			) }
		>
			{ children }
		</div>
	)
}

Buttons.propTypes = {
	size: PropTypes.string,
	children: PropTypes.node
}
