
import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'

export default function List( props ) {

	const {
		isPrimary,
		isLight,
		children,
		className
	} = props

	return (
		<div
			className={ classNames(
				'o-list',
				{
					// [`o-list--${theme}`]: theme
					'o-list--primary': isPrimary,
					'o-list--light': isLight
				},
				className
			) }
		>
			{ children }
		</div>
	)
}

List.propTypes = {
	isPrimary: PropTypes.bool,
	isLight: PropTypes.bool,
	children: PropTypes.node
}

export function ListItem( props ) {

	const {
		id,
		isPrimary,
		isLight,
		children,
		className
	} = props

	return (
		<div
			id={ id }
			className={ classNames(
				'o-list__item',
				{
					// [`o-list__item--${theme}`]: theme
					'o-list__item--primary': isPrimary
				},
				className
			) }
		>
			{ children }
		</div>
	)
}

ListItem.propTypes = {
	isPrimary: PropTypes.bool,
	children: PropTypes.node
}
