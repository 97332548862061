import { createContext, useContext, useState } from 'react'

const MenuContext = createContext()

export const ProvideMenu = ( { children, data } ) => {
	const dataWrapper = useProvideMenu()

	return (
		<MenuContext.Provider value={ dataWrapper }>
			{children}
		</MenuContext.Provider>
	)
}

// Hook for child components to get the Menu object ...
// ... and re-render when it changes.
export const useMenu = () => {
	return useContext(MenuContext)
}

// Provider hook that creates Data object and handles state
const useProvideMenu = () => {

	const [isOpen, setIsOpen] = useState( false )

	return {
		isOpen,
		setIsOpen
	}
}
