
import classNames from 'classnames'
import { useState, useRef, forwardRef } from 'react'
import PropTypes from 'prop-types'

import { Icon } from '../../components/media'

import { isFunction } from 'libs/utils'

export function FieldFeedback( { isContained, children } ) {

	if( ! children )
		return null

	return (
		<span
			className={ classNames(
				'o-form__field__feedback',
				{
					'o-form__field__feedback--contained': isContained
				}
			) }
			title={ children }
		>
			{ children }
		</span>
	)
}

export function FormField( props ) {

	const {
		id,
		isMultiple,
		isStretch,
		isInline,
		hasAddons,
		hasError,
		children,
		className
	} = props

	return (
		<div
			id={ id }
			className={ classNames(
				'o-form__field',
				{
					'o-form__field--multiple': isMultiple,
					'o-form__field--inline': isInline,
					'o-form__field--stretch': isStretch,
					'o-form__field--addons': hasAddons,
					'o-form__field--error': hasError
				},
				className
			) }
		>
			{ children }
		</div>
	)
}

export function FormControl( props ) {

	const {
		id,
		isPrimary,
		hasError,
		children
	} = props

	return (
		<div
			id={ id }
			className={ classNames(
				'o-form__control',
				{
					'o-form__control--primary': isPrimary,
					'o-form__control--error': hasError
				}
			) }
		>
			{ children }
		</div>
	)
}

export function Label( props ) {

	const {
		htmlFor,
		size,
		hasError,
		children,
		isRequired,
		isPrimary,
		onClick,
		className
	} = props

	return (
		<label
			htmlFor={ htmlFor }
			className={ classNames(
				'o-label',
				{
					[`o-label--${size}`]: size,
					'o-label--error': hasError,
					'o-label--primary': isPrimary
				},
				className
			) }
			onClick={ onClick }
		>
			{ children }
			{ isRequired && (
				<>
					&nbsp;<span className="o-label__required">*</span>
				</>
			) }
		</label>
	)
}

Label.propTypes = {
	htmlFor: PropTypes.string,
	children: PropTypes.oneOfType( [
		PropTypes.string,
		PropTypes.number,
		PropTypes.element
	] ),
	isRequired: PropTypes.bool,
	hasError: PropTypes.bool,
	size: PropTypes.string,
	className: PropTypes.string
}

export const Input = forwardRef( (props, ref) => {

	const {
		className,
		checked,
		hasError,
		color,
		size,
		label,
		values,
		names,
		isDisabled,
		isRequired,
		isStretch,
		isInline,
		field,
		form,
		tabIndex,
		innerRef,
		...attrs
	} = props

	const name = field ? field.name : attrs.name
	const value = field ? field.value : attrs.value
	const onChange = field ? field.onChange : attrs.onChange
	const onBlur = field ? field.onBlur : attrs.onBlur
	const classType = attrs.type !== 'checkbox' && attrs.type !== 'radio' ? 'input' : attrs.type

	return (
		<input
			ref={ innerRef || ref }
			className={ classNames(
				[`o-${classType}`],
				{
					[`o-${attrs.type}`]: classType !== attrs.type,
					[`o-${classType}--${size}`]: size,
					[`o-${classType}--color-${color}`]: color,
					[`o-${classType}--error`]: hasError,
					[`o-${classType}--disabled`]: isDisabled
				},
				className
			) }
			defaultChecked={ checked }
			disabled={ isDisabled }
			tabIndex={ isDisabled ? -1 : tabIndex }
			{ ...attrs }
			name={ name }
			value={ value }
			onChange={ onChange }
			onBlur={ onBlur }
		/>
	)
} )

Input.defaultProps = {
	type: 'text',
	value: undefined
}

Input.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	type: PropTypes.string,
	value: PropTypes.oneOfType( [
		PropTypes.bool,
		PropTypes.string
	] ),
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	className: PropTypes.string,
	hasError: PropTypes.bool,
	color: PropTypes.string,
	isFocused: PropTypes.bool
}

export function Password( { isPrimary, size, innerRef, ...props } ) {

	const [type, setType] = useState('password')

	return (
		<>
			<Input
				ref={ innerRef }
				{ ...props }
				type={ type }
			/>
			<span className="o-field__icon">
				<Icon
					name={ type === 'password' ? 'show' : 'hide' }
					size={ size }
					onClick={ () => setType( type === 'text' ? 'password' : 'text' ) }
				/>
			</span>
		</>
	)
}

Password.defaultProps = {
	type: 'text',
	value: undefined
}

Password.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	isRequired: PropTypes.bool,
	placeholder: PropTypes.string,
	type: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	className: PropTypes.string,
	hasError: PropTypes.bool,
	color: PropTypes.string,
	isFocused: PropTypes.bool,
	onClick: PropTypes.func
}

export function Textarea( props ) {

	const {
		id,
		name,
		isRequired,
		placeholder,
		maxLength,
		value,
		onChange,
		onBlur,
		disabled,
		className,
		hasError,
		color,
		size,
		rows,
		tabIndex,
		isFocused
	} = props

	return (
		<textarea
			id={ id }
			name={ name }
			className={ classNames(
				'o-textarea',
				{
					[`o-textarea--${size}`]: size,
					[`o-textarea--color-${color}`]: color,
					'o-textarea--error': hasError
				},
				className
			) }
			value={ value }
			placeholder={ placeholder }
			onChange={ onChange }
			onBlur={ onBlur }
			rows={ rows }
			disabled={ disabled }
			tabIndex={ disabled ? -1 : tabIndex }
			maxLength={ maxLength }
		/>
	)
}

Textarea.defaultProps = {
	value: undefined,
	rows: 5
}

Textarea.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	isRequired: PropTypes.bool,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	className: PropTypes.string,
	hasError: PropTypes.bool,
	color: PropTypes.string,
	rows: PropTypes.number,
	isFocused: PropTypes.bool
}

export function Select( props ) {

	const {
		id,
		name,
		placeholder,
		value,
		onChange,
		onBlur,
		disabled,
		className,
		hasError,
		color,
		arrowColor,
		size,
		options,
		tabIndex,
		children,
		...attrs
	} = props

	return (
		<span
			className={ classNames(
				'o-select',
				{
					[`o-select--${size}`]: size,
					[`o-select--color-${color}`]: color,
					'o-select--error': hasError
				},
				className
			) }
		>
			<select
				id={ id }
				name={ name }
				value={ value }
				onChange={ onChange }
				onBlur={ onBlur }
				className="o-select__select"
				disabled={ disabled }
				tabIndex={ disabled ? -1 : tabIndex }
				{ ...attrs }
			>
				{ placeholder && <option value="">{ placeholder }</option> }
				{ options.map( (opt, index) => (
					'options' in opt && opt.options.length > 0
					? (
						<optgroup
							key={ `optgroup-${ opt.value || index }` }
							label={ opt.label }
							disabled={ opt.isDisabled }
						>
							{ opt.options.map( subopt => (
								<option
									key={ `optgroup-${ opt.value || index }-option-${ subopt.value }` }
									value={ subopt.value }
									disabled={ subopt.isDisabled }
								>
									{ subopt.label }
								</option>
							) ) }
						</optgroup>
					)
					: (
						<option
							key={ `option-${ opt.value }` }
							value={ opt.value }
							disabled={ opt.isDisabled }
						>
							{ opt.label }
						</option>
					)
				) ) }
				{ children }
			</select>
			<span className="o-select__icon o-field__icon">
				<Icon
					name="chevron-down"
					size={ size }
					color={ arrowColor }
				/>
			</span>
		</span>
	)
}

Select.defaultProps = {
	value: undefined,
	options: []
}

Select.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	className: PropTypes.string,
	hasError: PropTypes.bool,
	color: PropTypes.string,
	size: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape( {
			value: PropTypes.string,
			label: PropTypes.string
		} )
	),
	children: PropTypes.node,
	isFocused: PropTypes.bool
}

export function Toggle( props ) {

	const {
		id,
		name,
		value,
		values,
		onClick,
		onChange,
		onBlur,
		disabled,
		className,
		hasError,
		isLoading,
		label,
		color,
		size
	} = props

	const realValue = values.indexOf( value ) > -1 ? value : values[0]

	return (
		<FormControl>
			<input
				id={ id }
				name={ name }
				type="hidden"
				className={ classNames(
					'o-toggle',
					{
						[`o-toggle--${size}`]: size,
						[`o-toggle--color-${color}`]: color,
						'o-toggle--error': hasError,
						'o-toggle--loading': isLoading,
						'o-toggle--checked': values[1] === realValue
					},
					className
				) }
				value={ realValue }
				onChange={ onChange }
				onBlur={ onBlur }
				disabled={ disabled }
			/>
			<Label
				htmlFor={ id }
				onClick={ onClick }
			>
				{ label }
			</Label>
		</FormControl>
	)
}

Toggle.defaultProps = {
	value: false,
	values: [
		false,
		true
	]
}

Toggle.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.oneOfType( [
		PropTypes.bool,
		PropTypes.string
	] ),
	values: PropTypes.arrayOf(
		PropTypes.oneOfType( [
			PropTypes.bool,
			PropTypes.string
		] )
	),
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	className: PropTypes.string,
	hasError: PropTypes.bool,
	color: PropTypes.string,
	size: PropTypes.string,
	isFocused: PropTypes.bool
}

export function Radio( props ) {

	const {
		label,
		values,
		names,
		...attrs
	} = props

	return (
		<Input
			{ ...attrs }
			type="radio"
		/>
	)
}

export function Checkbox( props ) {

	const {
		label,
		values,
		names,
		...attrs
	} = props

	return (
		<Input
			{ ...attrs }
			type="checkbox"
		/>
	)
}

export function Quantity( props ) {

	const {
		id,
		name,
		placeholder,
		min,
		max,
		value,
		onChange,
		onChangeCustom,
		onBlur,
		disabled,
		className,
		hasError,
		isLoading,
		color,
		arrowColor,
		size
	} = props

	const handleChange = e => {
		if( isFunction( onChange ) ) {
			onChange( e )
		}
		if( isFunction( onChangeCustom ) ) {
			onChangeCustom( e )
		}
	}



	// TODO: trigger change event when input's value is programaticaly changed
	const inputRef = useRef(null)
	const handleMinus = e => {
		inputRef.current.stepDown()
		triggerChange()
	}
	const handlePlus = e => {
		inputRef.current.stepUp()
		triggerChange()
	}
	const triggerChange = () => {
		const e = new Event('change', { bubbles: true})
		inputRef.current.dispatchEvent(e)
	}

	return (
		<div
			className={ classNames(
				'o-quantity',
				{
					[`o-quantity--${size}`]: size,
					[`o-quantity--color-${color}`]: color,
					'o-quantity--error': hasError,
					'u-loader': isLoading
				},
				className
			) }
		>
			<div className="u-loader__container">

				<span className="o-field__icon o-field__icon--left o-quantity__minus">
					<Icon
						name="chevron-left"
						size={ size }
						color={ arrowColor }
						onClick={ handleMinus }
						isDisabled={ value === min ? true : null }
					/>
				</span>
				<input
					ref={ inputRef }
					id={ id }
					name={ name }
					className="o-quantity__input u-loader__value"
					type="number"
					min={ min }
					max={ max }
					value={ value }
					onChange={ handleChange }
					onBlur={ onBlur }
					disabled={ disabled }
				/>
				<span className="o-field__icon o-field__icon--right o-quantity__plus">
					<Icon
						name="chevron-right"
						size={ size }
						color={ arrowColor }
						onClick={ handlePlus }
						isDisabled={ value === max ? true : null }
					/>
				</span>

			</div>
		</div>
	)
}

Quantity.defaultProps = {
	type: 'number',
	min: 0,
	value: undefined
}

Quantity.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	min: PropTypes.number,
	max: PropTypes.number,
	value: PropTypes.number,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	handleMinus: PropTypes.func,
	handlePlus: PropTypes.func,
	className: PropTypes.string,
	hasError: PropTypes.bool,
	color: PropTypes.string,
	isLoading: PropTypes.bool
}
